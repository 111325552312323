// Import React Components
import React from "react";
import { Route } from "react-router-dom";

import AdminNav from "../../components/navs/AdminNav";
import AdminDashboard from "./AdminDashboard";
import AdminEvents from "./AdminEvents";
import AdminMembers from "./AdminMembers";
import EventForm from "../events/EventForm";
import AdminPosts from "./AdminPosts";
import AdminNewPost from "./AdminNewPost";
import AdminEditPost from "./AdminEditPost";
import EditUserProfile from "../../components/profile/membership/EditUserProfile";

// Define the Routes structure here and export it to App.js to avoid cluttering
export const AdminRoutes = (
  <Route element={<AdminNav />}>
    <Route path="admin">
      <Route index element={<AdminDashboard />}></Route>
      <Route path="posts">
        <Route index element={<AdminPosts />} />
        <Route path="newpost" element={<AdminNewPost />} />
        <Route path=":postId" element={<AdminEditPost />} />
      </Route>
      <Route path="events">
        <Route index element={<AdminEvents />} />
        <Route path="newevent" element={<EventForm />} />
      </Route>
      <Route path="members">
        <Route index element={<AdminMembers />} />
        <Route path="edit" element={<EditUserProfile />} />
      </Route>
    </Route>
  </Route>
);
