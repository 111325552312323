// Import React hooks
import React, { useRef, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Import Custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Material UI Components
import { Box, Button, Grid, TextField } from "@mui/material";

// Import Custom Components
import Loading from "../../components/ui/Loading";

// Import Context
import AuthContext from "../../context/auth-context";
import FeedbackContext from "../../context/feedback-context";

// Import Types
import { LoginRequest, LoginResponse } from "../../types/auth/login";

function Login() {
  const { loading, sendRequest } = useSendRequest();
  const navigate = useNavigate();
  const location = useLocation();

  const authCtx = useContext(AuthContext);
  const feedbackCtx = useContext(FeedbackContext);

  const emailInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();

  const loginHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const data: LoginRequest = {
      email: emailInputRef.current.value,
      password: passwordInputRef.current.value,
    };

    try {
      const response = await sendRequest("post", "/auth/login", data, true);
      const responseData: LoginResponse = response.data;
      authCtx.login(responseData);

      feedbackCtx.showFeedback("Login successfull", "success");

      if (location.state?.from) {
        // If the user was redirected to the login page, redirect them back to the page they were on
        navigate(location.state.from.pathname, { replace: true });
      } else {
        // Otherwise, redirect them to the profile page
        navigate(`/profile`, { replace: true });
      }
    } catch (error) {
      // If the user is not verified, tell them to check their emails
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.error === "User not verified"
      ) {
        feedbackCtx.showFeedback(
          "User not verified, please check your emails",
          "error"
        );
        return;
      }
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Login failed: " + error_msg, "error");
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid
        component="form"
        onSubmit={loginHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "60%",
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Login
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={emailInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="E-Mail"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={passwordInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            required
          />
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
          item
          xs={12}
        >
          <Button variant="contained" sx={{ width: "80%" }} type="submit">
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
          <Button sx={{ width: "80%" }} component={Link} to={`/auth/register`}>
            Dont have an account
          </Button>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
          <Button sx={{ width: "80%" }} component={Link} to={`/auth/forgotpw`}>
            Forgot password
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
