// These options often come together, to save some codelines and reduce cluttering, provide some named combinations
export const flexColCentered = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const flexRowCentered = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};
