// Import React Components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useSendRequest from "../../hooks/useSendRequest";
import { GetPostsResponse } from "../../types/posts/posts";
import { PostType } from "../../types/models/posts";

function AdminPosts() {
  const { sendRequest } = useSendRequest();
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await sendRequest("GET", "/posts/", {});
        const responseData: GetPostsResponse = response.data;
        setPosts(responseData.posts);
      } catch (error) {
        console.error("Fehler beim Abrufen der Posts: ", error);
      }
    };
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Link to={"/admin/posts/newpost"}>
        add new post (Functional but looks like crap)
      </Link>
      {posts.map((post) => (
        <div key={post._id}>
          <h2>{post.title}</h2>
          <p>{post.author}</p>
          <p>{post.content}</p>
          <Link to={`/admin/posts/${post._id}`}>Edit</Link>
        </div>
      ))}
    </div>
  );
}

export default AdminPosts;
