// Import React
import React from "react";

// Import Material UI Components
import { Box } from "@mui/material";

// Import custom components
import HomeIntroduction from "../components/home/HomeIntroduction";
import HomeEvents from "../components/home/HomeEvents";
import HomeUsers from "../components/home/HomeUsers";
import HomeFooter from "../components/home/HomeFooter";

function Home() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: 5,
          whoAreWeIsInView: "flex",
          flexDirection: "column",
        }}
      >
        <HomeIntroduction />
        <HomeEvents />
        <HomeUsers />
        <HomeFooter />
      </Box>
    </>
  );
}

export default Home;
