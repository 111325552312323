import React from "react";

// Import Material UI Components
import { Container, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  search: string;
  onSearch: React.ChangeEventHandler<HTMLInputElement>;
};

function Searchbar({ search, onSearch }: Props) {
  return (
    <Container sx={{ pt: 3, pb: 5, display: "flex", justifyContent: "center" }}>
      <TextField
        sx={{ width: "50%" }}
        onChange={onSearch}
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
}

export default Searchbar;
