import React, { useRef, useContext, useState } from "react";
import { Link } from "react-router-dom";

//Import custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Material UI Components
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Import custom components
import Loading from "../../components/ui/Loading";

// Import Context
import FeedbackContext from "../../context/feedback-context";

function ForgotPW() {
  const { loading, sendRequest } = useSendRequest();
  const [sent, setSent] = useState(false);

  const feedbackCtx = useContext(FeedbackContext);
  const emailInputRef = useRef<HTMLInputElement>();

  const forgotPWHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      email: emailInputRef.current.value,
    };
    try {
      await sendRequest("post", "/auth/forgotpw", data);
      feedbackCtx.showFeedback(
        "Email sent successfully, check your mail Inbox",
        "success"
      );
      setSent(true);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback(
        "Updating password failed: " + error_msg,
        "error"
      );
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid
        component="form"
        onSubmit={forgotPWHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "60%",
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Reset Password
          </Box>
        </Grid>
        <Grid item xs={6}>
          {!sent ? (
            <TextField
              inputRef={emailInputRef}
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="E-Mail"
              variant="outlined"
              required
            />
          ) : (
            <Typography textAlign={"center"}>
              Email was sent, check your mail Inbox !
            </Typography>
          )}
        </Grid>

        <Grid
          sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
          item
          xs={12}
        >
          <Button variant="contained" sx={{ width: "80%" }} type="submit">
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
          <Button sx={{ width: "80%" }} component={Link} to={`/auth`}>
            I remember my password
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPW;
