enum UserRoles {
  ADMIN = "admin",
  BOARD = "board",
  PRESIDENT = "president",
  VICEPRESIDENT = "vicePresident",
  FINANCE = "finance",
  IT = "IT",
  SOCIALRESSORT = "socialRessort",
  GENERALSECRETARY = "generalSecretary",
  MEMBERSRESPONSIBLE = "membersResponsible",
  COMMUNICATIONS = "communications",
  ACTIVEMEMBER = "activeMember",
  PASSIVEMEMBER = "passiveMember",
}

export default UserRoles;
