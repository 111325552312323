/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";

import useSendRequestWithToken from "../hooks/useSendRequestWithToken";

// Import other context
import AuthContext from "./auth-context";
import SocketContext from "./socket-context";

// Import types
import { ChildrenProp } from "../types/props/common";
import { GetChatsResponse } from "../types/chats/chats";
import { ChatType } from "../types/models/chats";

const NotificationContext = React.createContext({
  chatNotifications: [],
  addChatNotification: (chatID: string) => {},
  removeChatNotification: (chatIDtoRemove: string) => {},
});

export function NotificationContextProvider(props: ChildrenProp) {
  const [chatNotifications, setChatNotifications] = useState([]);
  const [chats, setChats] = useState<ChatType[]>([]);

  const { sendRequest } = useSendRequestWithToken();
  const authCtx = useContext(AuthContext);
  const { chatSocket: socket } = useContext(SocketContext);

  const addChatNotification = (chatID: string) => {
    setChatNotifications((chatIDs) => {
      return [...chatIDs, chatID];
    });
  };

  const removeChatNotification = (chatIDtoRemove: string) => {
    setChatNotifications((chatIDs) => {
      return chatIDs.filter((chatID) => chatID !== chatIDtoRemove);
    });
  };

  // add listener to add a notification whenever a new message arrives
  useEffect(() => {
    socket.on("newMessageReceivedNotification", (chatID: string) => {
      addChatNotification(chatID);
    });
    return () => {
      socket.off("newMessageReceivedNotification");
    };
  }, [socket]);

  // get the chats when the user logs in
  useEffect(() => {
    const getChats = async () => {
      try {
        const response = await sendRequest("get", "/chat", {});
        const data: GetChatsResponse = response.data;
        setChats(data.chats);
      } catch (error) {
        console.log(error);
      }
    };

    if (authCtx.userId) {
      getChats();

      socket.on("newChatIsCreated", getChats);
      return () => {
        socket.off("newChatIsCreated", getChats);
      };
    }
  }, [socket, authCtx.userId]);

  // subscribe to the chat notification rooms
  useEffect(() => {
    if (chats.length > 0) {
      // join all the chat rooms of the user
      for (const chat of chats) {
        socket.emit("JoinChatRoom", chat._id);
        // check if there are any unread messages
        const unreadMessages = chat.messages.some((message) => {
          return !message.seen && message.userID !== authCtx.userId;
        });
        if (unreadMessages) {
          addChatNotification(chat._id);
        }
      }
      return () => {
        for (const chat of chats) {
          socket.emit("LeaveChatRoom", chat._id);
        }
      };
    }
  }, [socket, chats]);

  return (
    <NotificationContext.Provider
      value={{
        chatNotifications: chatNotifications,
        addChatNotification: addChatNotification,
        removeChatNotification: removeChatNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
