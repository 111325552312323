import React from "react";

// Import Material UI Components
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

function Loading() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        /* height: "50rem",
        backgroundColor: "red", */
        width: "100%",
      }}
    >
      <LinearProgress />
    </Box>
  );
}

export default Loading;
