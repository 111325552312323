import axios from "../services/axios";
import { useState, useContext } from "react";

// import custom hooks
import useRefreshToken from "./useRefreshToken";
import useLogout from "./useLogout";

import AuthContext from "../context/auth-context";

// Hook to attach the access token to the sent request. Potentially refreshing the token if the token is expired. If token is invalid, return an error
const useSendRequestWithToken = () => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefreshToken();
  const authCtx = useContext(AuthContext);

  const logout = useLogout();

  const sendRequest = async <T,>(
    method: string,
    url: string,
    data: T,
    contentType: string = "application/json"
  ) => {
    const timeout = 3000;
    try {
      setLoading(true);
      // Attach the JWT and send request
      const first_response = await axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${authCtx.token}`,
        },
        withCredentials: true,
        timeout: timeout,
        data: data,
      });
      return first_response;
    } catch (error) {
      // First request failed
      if (
        error?.response?.status === 401 &&
        error.response.data.message === "invalid token"
      ) {
        try {
          // If the JWT is invalid, error code = 401 (Unauthorized) gets returned. However this might also mean that the token is expired, so refresh the token and try again
          const newAccessToken = await refresh();
          const second_response = await axios({
            method: method,
            url: url,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${newAccessToken}`,
            },
            withCredentials: true,
            timeout: timeout,
            data: data,
          });
          return second_response;
        } catch (error) {
          // Second request failed
          if (
            error.response.status === 401 &&
            error.response.data.message === "invalid token"
          ) {
            logout();
            throw error;
          } else {
            throw error;
          }
        }
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    sendRequest,
  };
};

export default useSendRequestWithToken;
