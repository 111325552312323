import React from "react";

import { Box, Typography } from "@mui/material";

import { flexColCentered } from "../../../../styles/commonStyles";

type EventDetailsPastProps = {
  participated: boolean;
};

function EventDetailsPast({ participated }: EventDetailsPastProps) {
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "0.25rem",
        mt: "1rem",
        py: "1rem",
        backgroundColor: "background.secondary",
        ...flexColCentered,
      }}
    >
      <Typography variant="h4" sx={{ px: "1rem", my: "1rem" }}>
        Sign in is closed
      </Typography>
    </Box>
  );
}

export default EventDetailsPast;
