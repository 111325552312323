// Import React
import React, { useContext } from "react";
import { Outlet, useLocation, Link, useOutletContext } from "react-router-dom";

// Material UI Components
import { AppBar, Box, Tabs, Tab, Badge } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Person2Icon from "@mui/icons-material/Person2";
import PasswordIcon from "@mui/icons-material/Password";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PaymentIcon from "@mui/icons-material/Payment";
import EventIcon from "@mui/icons-material/Event";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

// Import Custom hooks
import useGetDimension from "../../hooks/useGetDimension";

// Context
import NotificationContext from "../../context/notification-context";

// Import types
import { MainNavOutletCtxType } from "../../types/navs/mainNav";

// Import types

function UserNav() {
  const { chatNotifications } = useContext(NotificationContext);
  const outletCtx = useOutletContext<MainNavOutletCtxType>();
  const location = useLocation();
  const path = location.pathname;

  // Custom Hook to get dimension of Nav to calculate the margin needed for the pages
  const { ref, getDimension } = useGetDimension();

  const leftMargin = getDimension("width") + 2 * getDimension("left");
  const leftOffset = getDimension("left");

  // Map react router routes to MUI tabs values. These funtions are used to sync React Router with MUI Tabs (bidirectional)
  // IMPORTNT: When adding new tabs, this function should be updated aswell!
  const pagesTabValues = () => {
    if (path === `/profile`) {
      return "dashboard";
    }
    if (path === `/profile/password`) {
      return "password";
    }
    if (path === `/profile/manage`) {
      return "manage";
    }
    if (path === `/profile/membership`) {
      return "membership";
    }
    if (path === `/profile/chat`) {
      return "chat";
    }
    return false;
  };

  // Navigationbar for big screen size
  const BigScreen = () => {
    // Render the Available Tabs in seperate functions for better readability. PageTabs are tabs in the center and OptionTabs are tabs on the right
    // IMPORTANT: When adding new tabs, here is the place to add them, dont forget to update the function "pagesTabValues" to keep Tabs and React Router in sync
    const renderTabs = () => {
      return [
        <Tab
          key="dashboard"
          component={Link}
          to={`/profile`}
          label="Dashboard"
          value={"dashboard"}
          icon={<DashboardIcon />}
        />,
        <Tab
          key="manage"
          component={Link}
          to={`/profile/manage`}
          label="Manage profile"
          value={"manage"}
          icon={<Person2Icon />}
        />,
        <Tab
          key="password"
          component={Link}
          to={`/profile/password`}
          label="Update password"
          value={"password"}
          icon={<PasswordIcon />}
        />,
        <Tab
          key="membership"
          component={Link}
          to={`/profile/membership`}
          label="Manage Membership"
          value={"membership"}
          icon={<CardMembershipIcon />}
        />,
        <Tab
          disabled
          key="payments"
          component={Link}
          to={`/profile/payment`}
          label="Manage Payments"
          value={"tbd"}
          icon={<PaymentIcon />}
        />,
        <Tab
          disabled
          key="events"
          component={Link}
          to={`/profile/events`}
          label="Manage Events"
          value={"tbd"}
          icon={<EventIcon />}
        />,
        <Tab
          key="chat"
          component={Link}
          to={`/profile/chat`}
          label="chat"
          value={"chat"}
          icon={
            chatNotifications && chatNotifications.length > 0 ? (
              <Badge variant="dot" color="error">
                <ChatBubbleOutlineIcon />
              </Badge>
            ) : (
              <ChatBubbleOutlineIcon />
            )
          }
        />,
        <Tab
          disabled
          key="settings"
          component={Link}
          to={`/profile/settings`}
          label="Settings"
          value={"tbd"}
          icon={<SettingsIcon />}
        />,
      ];
    };

    return (
      <Tabs
        orientation="vertical"
        value={pagesTabValues()}
        textColor="inherit"
        indicatorColor="secondary"
        centered
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {renderTabs()}
      </Tabs>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          width: "18%",
          top: `${outletCtx.topMargin}px`,
          left: "2%",
          borderRadius: "0.25rem",
          backgroundColor: "primary.main",
        }}
        ref={ref}
        position="fixed"
      >
        {BigScreen()}
      </AppBar>
      <Box sx={{ pl: `${leftMargin}px`, pr: `${leftOffset}px`, width: "100%" }}>
        <Outlet />
      </Box>
    </>
  );
}

export default UserNav;
