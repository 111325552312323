// Hooks
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Material UI components
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Import Custom Components
import Loading from "../../components/ui/Loading";
import ImgSlider from "../../components/ui/ImgSlider";
import EventDetailActions from "../../components/events/signingIn/EventDetailsActions";

// Imort Context
import AuthContext from "../../context/auth-context";

// Types
import { flexRowCentered, flexColCentered } from "../../styles/commonStyles";
import { EventDetailOptions } from "../../types/enums/events";
import { GetEventDetailsResponse } from "../../types/events/eventDetails";
import { RegistrationStatusEnum } from "../../types/enums/eventRegistration";

import vars from "../../confidential.json";

function EventDetails() {
  // State variable holding event data
  const [eventDetailsInfo, setEventDetailsInfo] =
    useState<GetEventDetailsResponse>();

  const [type, setType] = useState<EventDetailOptions>();
  const { eventId } = useParams();

  const authCtx = useContext(AuthContext);

  // some hooks needed for routing
  const navigate = useNavigate();

  // Two Custom hooks to send request to the backend
  const { loading, sendRequest } = useSendRequestWithToken();

  const getEventDetailsInfo = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `/events/getEventDetailsInfo/${eventId}`,
        {}
      );
      const data: GetEventDetailsResponse = response.data;
      setEventDetailsInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEventDetailsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This logic picks the right type of action to display based on the event details and state of the event
  // Doesnt need to be in a useEffect
  useEffect(() => {
    if (!eventDetailsInfo) {
      return;
    }
    const eventIsFull =
      eventDetailsInfo.registrationsInfo.numRegistrations >=
      eventDetailsInfo.event.limit;
    const isPast =
      new Date(eventDetailsInfo.event.eventDate) < new Date(Date.now());

    if (!authCtx.isLoggedIn) {
      return setType(EventDetailOptions.LOGIN);
    }
    if (isPast) {
      return setType(EventDetailOptions.PAST);
    }
    if (!eventDetailsInfo.registration) {
      if (eventIsFull) {
        return setType(EventDetailOptions.ENTER_WAITINGLIST);
      }
      return setType(EventDetailOptions.SIGNIN);
    }
    if (eventDetailsInfo.registration) {
      if (
        eventDetailsInfo.registration.status ===
        RegistrationStatusEnum.WAITINGLIST
      ) {
        return setType(EventDetailOptions.LEAVE_WAITINGLIST);
      }
      return setType(EventDetailOptions.SIGNOUT);
    }
  }, [eventDetailsInfo, authCtx]);

  if (!eventDetailsInfo) {
    return <Loading />;
  }

  const eventData = eventDetailsInfo.event;
  return (
    <Container maxWidth="xl">
      {loading && <Loading />}
      <IconButton
        sx={{ position: "fixed", top: "140px", left: "4%" }}
        onClick={() => {
          navigate("..", {
            state: { date: new Date(eventData.eventDate) },
          });
        }}
      >
        <KeyboardReturnIcon sx={{ fontSize: "4rem", color: "black" }} />
      </IconButton>
      <Grid
        container
        sx={{ ...flexRowCentered, alignItems: "start", px: "2%", mb: "2rem" }}
      >
        <Grid
          container
          item
          xs={6}
          sx={{
            ...flexColCentered,
            justifyContent: "start",
            pr: "2rem",
            height: "80vh",
            overflow: "scroll",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 4,
              py: "1.5rem",
              width: "100%",
              borderRadius: "0.25rem",
              backgroundColor: "background.secondary",
              textAlign: "center",
            }}
          >
            {eventData.name}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", my: "1rem" }}>
            <AccessTimeIcon sx={{ mr: 1 }} />
            <Typography
              sx={{
                fontStyle: "italic",
                opacity: "0.6",
                fontSize: "0.9rem",
              }}
            >
              {new Date(eventData.eventDate).toLocaleString("en-GB", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </Typography>
          </Box>

          <Typography sx={{ my: "1rem" }}>{eventData.description}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{ width: "100%", borderRadius: "0.25rem" }}
            component="img"
            src={vars.backend_URL + "/events/" + eventData.flyerImage.filename}
          />
          {type && eventData && (
            <EventDetailActions
              type={type}
              eventDetailsInfo={eventDetailsInfo}
              getEventDetailsInfo={getEventDetailsInfo}
            ></EventDetailActions>
          )}
        </Grid>
      </Grid>
      {eventData.eventImages.length > 0 && (
        <Box
          sx={{
            mt: "2rem",
            width: "100%",
            ...flexColCentered,
            backgroundColor: "background.secondary",
          }}
        >
          <Typography
            variant="h4"
            sx={{ textAlign: "center", pt: "3rem", pb: "2rem" }}
          >
            Pictures from last years Event
          </Typography>
          <ImgSlider
            height="600px"
            width="60%"
            imageUrls={eventData.eventImages.map(
              (image) => vars.backend_URL + "/events/" + image.filename
            )}
          />
        </Box>
      )}
    </Container>
  );
}

export default EventDetails;
