export enum RegistrationStatusEnum {
  PENDING = "pending",
  REGISTERED = "registered",
  WAITINGLIST = "waitinglist",
  CANCELLED = "cancelled",
  CANCELLED_WAITINGLIST = "cancelled_waitinglist",
  REMOVED = "removed",
  SHOWUP = "showup",
  NO_SHOWUP = "no_showup",
}

export enum ExtraQuestionsTypeEnum {
  TEXTFIELD = "textfield",
  SINGLESELECT = "singleSelect",
  MULTISELECT = "multiSelect",
}
