import React, { useState } from "react";
import { ChildrenProp } from "../types/props/common";
import { AlertColor } from "@mui/material";

// This context is concerning the feedback alert bar shown in case of infos, success massages and error
const FeedbackContext = React.createContext({
  open: false,
  message: "",
  duration: 0,
  severity: "info" as AlertColor,
  showFeedback: (message: string, severity: string, duration?: number) => {},
  closeFeedbackHandler: () => {},
});

export function FeedbackContextProvider(props: ChildrenProp) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(0);
  const [severity, setSeverity] = useState<AlertColor>("info");

  // severity can be one of the following string values: ["success", "errorr", "info"]
  const showFeedbackHandler = (
    message: string,
    severity: AlertColor,
    duration = 5000
  ) => {
    setOpen(true);
    setMessage(message);
    setDuration(duration);
    setSeverity(severity);
  };

  const closeFeedbackHandler = () => {
    setOpen(false);
  };

  return (
    <FeedbackContext.Provider
      value={{
        open: open,
        message: message,
        duration: duration,
        severity: severity,
        showFeedback: showFeedbackHandler,
        closeFeedbackHandler: closeFeedbackHandler,
      }}
    >
      {props.children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;
