// Import React Components hooks
import React, { useState, useEffect, useRef, forwardRef } from "react";

// Import custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Animation lib
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimate,
  useInView,
} from "framer-motion";

// Import Material UI
import {
  Box,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";

// Import styles
import { flexRowCentered, flexColCentered } from "../../styles/commonStyles";
import { Link } from "react-router-dom";
import { ThemePalette } from "../../types/styles/themePalette";
import { ChildrenProp } from "../../types/props/common";

type TabPanelProps = {
  value: number;
  index: number;
  sx: SxProps;
} & ChildrenProp;

// Small Component for the Tabs content. depending on which tab is active set all the other tabs to hidden
const TabPanel = forwardRef(function TabPanel(props: TabPanelProps, ref: any) {
  const { children, value, index, sx } = props;

  return (
    <div ref={ref} hidden={value !== index}>
      {value === index && <Box sx={{ p: 3, ...sx }}>{children}</Box>}
    </div>
  );
});

function HomeEvents() {
  const [numEvents, setNumEvents] = useState(0);
  const [Tabvalue, setTabValue] = useState(0);
  const ref = useRef<HTMLElement>();
  const { sendRequest } = useSendRequest();
  const [, animate] = useAnimate();
  const theme = useTheme<ThemePalette>();

  // Some hooks provided by framer motion for animating counting numbers
  const countEvents = useMotionValue(0);
  const roundedEvents = useTransform(countEvents, Math.round);
  const isInView = useInView(ref, { amount: 0.8, once: true });

  const handleTabChange = (event: any, newValue: number) => {
    countEvents.set(0);
    setTabValue(newValue);
  };

  // Use Effect function to fetch some data that will get displayed
  useEffect(() => {
    const getEvents = async () => {
      try {
        const eventsResponse = await sendRequest("GET", "/events", {});
        setNumEvents(eventsResponse.data.events.length);
      } catch (error) {
        console.log("error: ", error);
      }
    };

    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is used for animating the numbers as soon as the section is in View
  useEffect(() => {
    if (isInView) {
      const EventsAnimation = animate(countEvents, numEvents, {
        duration: 2,
        ease: "easeOut",
      });

      return EventsAnimation.stop;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tabvalue, isInView]);

  // To avoid cluttering, move the tab content here, it will be called later to return the sjx needed
  const EventsTab = () => {
    return (
      <>
        <Box sx={{ ...flexRowCentered, mb: 2 }}>
          <Typography variant="h4" sx={{ px: 2, fontWeight: "bold" }}>
            # Events :
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            style={{
              fontSize: "5rem",
              fontWeight: "bold",
              color: theme.palette.button.secondary_bg.main,
            }}
          >
            {roundedEvents}
          </motion.div>
        </Box>
        <Typography sx={{ mb: 8 }}>We organize multiple events</Typography>
        <Button
          variant="contained"
          component={Link}
          to={"/events"}
          sx={{ background: theme.palette.button.secondary_bg.main }}
        >
          more
        </Button>
      </>
    );
  };

  const PrayersTab = () => {
    return (
      <>
        <Typography sx={{ textAlign: "center" }}> Prayer </Typography>
        <Button> more </Button>
      </>
    );
  };

  const KnowledgeTab = () => {
    return (
      <>
        <Typography sx={{ textAlign: "center" }}> Knowledge </Typography>
        <Button> more </Button>
      </>
    );
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.secondary" }}>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Grid container sx={flexRowCentered}>
          <Grid item xs={8} md={4} sx={{ py: "5rem" }}>
            <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold" }}>
              What do we do ?
            </Typography>
            <Typography>
              We organize regularly a big variety of events as well as in
              cooperation with other student associations. Everyone is welcome!
              Stay updated by joining our whatsapp announcement chat and follow
              us on Instagram! <br />
              <br />
              We do also provide services like a prayer room in multiple
              faculties as well as friday prayer. Of course if you have any
              requests or questions dont hesitate to contact us!
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            md={6}
            sx={{
              alignSelf: "start",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              py: 3,
            }}
          >
            <Tabs
              value={Tabvalue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Events" />
              <Tab label="Prayer" />
              <Tab label="Knowledge" />
            </Tabs>
            <TabPanel
              ref={ref}
              sx={{ ...flexColCentered }}
              value={Tabvalue}
              index={0}
            >
              {EventsTab()}
            </TabPanel>
            <TabPanel sx={{ ...flexColCentered }} value={Tabvalue} index={1}>
              {PrayersTab()}
            </TabPanel>
            <TabPanel sx={{ ...flexColCentered }} value={Tabvalue} index={2}>
              {KnowledgeTab()}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomeEvents;
