import axios from "../services/axios";
import { useContext } from "react";

// Import context
import AuthContext from "../context/auth-context";
import FeedbackContext from "../context/feedback-context";

// Simple hook that logs out user
function useLogout() {
  const authCtx = useContext(AuthContext);
  const feedbackCtx = useContext(FeedbackContext);

  const logout = async () => {
    try {
      await axios.get("/auth/logout", {
        withCredentials: true,
      });
      authCtx.logout();
      feedbackCtx.showFeedback("Logged out", "success");
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Error at logging out: " + error_msg, "error");
    }
  };

  return logout;
}

export default useLogout;
