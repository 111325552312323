// Import React hooks
import React from "react";

// Import Material UI Components
import { IconButton, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type EventSelectorProps = {
  year: number;
  maxYear: number;
  minYear: number;
  onChangeYear: (action: "increase" | "decrease") => void;
};

function EventSelector(props: EventSelectorProps) {
  return (
    <Box
      sx={{
        cursor: "default",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <IconButton
        disabled={props.year <= props.minYear}
        onClick={() => props.onChangeYear("decrease")}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box sx={{ fontSize: "2rem", fontWeight: "bold", padding: "0 1rem" }}>
        {props.year}
      </Box>
      <IconButton
        disabled={props.year >= props.maxYear}
        onClick={() => props.onChangeYear("increase")}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}

export default EventSelector;
