// Hooks
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Material UI components
import { Box, Button, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Contexts
import ThemeContext from "../../context/theme-context";
import FeedbackContext from "../../context/feedback-context";

import { flexColCentered } from "../../styles/commonStyles";
import { GetPostResponse } from "../../types/posts/posts";

function AdminEditPost() {
  const { loading, sendRequest } = useSendRequestWithToken();
  const ctx = useContext(ThemeContext);
  const feedbackCtx = useContext(FeedbackContext);

  const { postId } = useParams();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await sendRequest("GET", `/posts/${postId}`, {});
        const responseData: GetPostResponse = response.data;
        reset(responseData.post);
      } catch (error) {}
    };
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, reset]);

  const submitHandler = async ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => {
    const data = {
      title,
      content,
    };

    try {
      await sendRequest("PUT", `/posts/${postId}`, data);
      feedbackCtx.showFeedback("Post editet succesfully", "success", 5000);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Editing post failed: " + error_msg, "error");
    }
  };

  return (
    <Box sx={{ ...flexColCentered }}>
      <Grid
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "80%",
          bgcolor: `background.${ctx.theme}`,
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Edit Post
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label=""
            variant="outlined"
            required
            {...register("title")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            sx={{ width: "100%" }}
            id="outlined-basic"
            label=""
            variant="outlined"
            required
            {...register("content")}
          />
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12}>
          {!loading ? (
            <Button sx={{ width: "80%" }} type="submit">
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminEditPost;
