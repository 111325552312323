import { ThemePalette } from "../types/styles/themePalette";

export const lightModePalette: ThemePalette = {
  palette: {
    background: {
      default: "hsl(205, 40%, 99%)",
      secondary: "hsl(205, 40%, 90%)",
    },
    primary: {
      main: "hsl(205, 40%, 55%)",
      light: "hsl(205, 40%, 80%)",
      dark: "hsl(205, 40%, 30%)",
    },
    button: {
      default_bg: {
        main: "hsl(205, 40%, 55%)",
      },
      secondary_bg: {
        main: "hsl(205, 40%, 55%)",
      },
    },
    text: {
      tabs: {
        selected: "hsl(205, 40%, 10%)",
        unselected: "hsl(205, 40%, 30%)",
      },
      dark: "hsl(205, 40%, 10%)",
      primary: "hsl(205, 40%, 20%)",
      light: "hsl(205, 40%, 30%)",
    },
  },
};

// palette values for dark mode
export const darkModePalette: ThemePalette = {
  palette: {
    background: {
      default: "hsl(205, 40%, 10%)",
      secondary: "hsl(205, 40%, 20%)",
    },
    primary: {
      main: "hsl(205, 40%, 20%)",
      light: "hsl(205, 40%, 40%)",
      dark: "hsl(205, 40%, 30%)",
    },
    button: {
      default_bg: {
        main: "hsl(205, 40%, 20%)",
      },
      secondary_bg: {
        main: "hsl(205, 40%, 10%)",
      },
    },
    text: {
      tabs: {
        selected: "hsl(205, 40%, 95%)",
        unselected: "hsl(205, 40%, 75%)",
      },
      light: "hsl(205, 40%, 99%)",
      primary: "hsl(205, 40%, 90%)",
      dark: "hsl(205, 40%, 80%)",
    },
  },
};
