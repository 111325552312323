// Import React hooks
import React, { useEffect, useState, useContext } from "react";

import { Grid, Typography, Divider, Button } from "@mui/material";

// Import Custom hooks
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Import Context
import NotificationContext from "../../context/notification-context";

// Import custom components
import Loading from "../../components/ui/Loading";
import { flexColCentered, flexRowCentered } from "../../styles/commonStyles";

// Import types
import { UserType } from "../../types/models/users";

function UserDashboard() {
  const { chatNotifications } = useContext(NotificationContext);
  const { loading, sendRequest } = useSendRequestWithToken();
  const [userInfo, setUserInfo] = useState<UserType>();

  const unreadMessages = chatNotifications && chatNotifications.length > 0;

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await sendRequest("GET", `/profile`, {});
        const userData: UserType = response.data.user;
        setUserInfo(userData);
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userInfo) return <Loading />;
  return (
    <>
      {loading && <Loading />}
      <Grid container sx={{ ...flexColCentered }}>
        <Grid item xs={12} sx={{ width: "100%", ...flexColCentered }}>
          <Typography variant="h3" sx={{ my: 5, color: "text.secondary" }}>
            Hello {userInfo.firstname}!
          </Typography>
          <Typography variant="h4" color={"red"}>
            This section is only for show, not functional yet
          </Typography>
        </Grid>
        <Divider sx={{ width: "60%", mb: 2 }} />
        <Grid container item xs={12} sx={{ my: 3, ...flexRowCentered }}>
          <Grid item xs={4} sx={flexColCentered}>
            <Typography>Status Membership </Typography>
            <Button variant="contained" sx={{ mt: 3 }}>
              manage membership
            </Button>
          </Grid>
          <Grid item xs={4} sx={flexColCentered}>
            <Typography> Status Payments</Typography>
            <Button variant="contained" sx={{ mt: 3 }}>
              manage payments
            </Button>
          </Grid>
          <Grid item xs={4} sx={flexColCentered}>
            <Typography sx={{}}>
              Status Messages:
              {unreadMessages ? " unread messages" : " nothing new"}
            </Typography>
            <Button variant="contained" sx={{ mt: 3 }}>
              check messages
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ width: "60%", my: 2 }} />
        <Grid container item xs={12} sx={{ my: 3, ...flexRowCentered }}>
          <Grid item xs={10} sx={{ ...flexColCentered, mb: 3 }}>
            <Typography> upcoming events you are in: </Typography>
            <Typography> List of events: To be implemented </Typography>
          </Grid>
          <Grid item xs={10} sx={{ ...flexColCentered }}>
            <Typography>Number of events you have participated in:</Typography>
            <Typography> 0 </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ width: "60%", my: 2 }} />
        <Grid container item xs={12} sx={{ my: 3, ...flexRowCentered }}>
          <Grid item xs={4} sx={{ ...flexColCentered, mb: 3 }}>
            TBD
          </Grid>
          <Grid item xs={4} sx={{ ...flexColCentered }}>
            TBD
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UserDashboard;
