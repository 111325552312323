import React, { useEffect, useState } from "react";

import { Button, Typography, Grid } from "@mui/material";

import Loading from "../../../ui/Loading";

import {
  flexRowCentered,
  flexColCentered,
} from "../../../../styles/commonStyles";

// Types
import { EventType } from "../../../../types/models/events";
import { ExtraQuestionsAnswers } from "../../../../types/eventRegistration/eventRegistration";
import { buttonSX } from "../../../../styles/eventDetailsActions";
import EventDetailsForm from "../EventDetailsForm";

type EventDetailsSignInProps = {
  eventData: EventType;
  signinHandler: (
    event: React.FormEvent<HTMLFormElement>,
    customQuestionsAnswers: ExtraQuestionsAnswers[]
  ) => void;
  loading: boolean;
};
function EventDetailsSignIn({
  eventData,
  signinHandler,
  loading,
}: EventDetailsSignInProps) {
  const [customQuestionsAnswers, setCustomQuestionsAnswers] =
    useState<ExtraQuestionsAnswers[]>();

  // on load, populate the custom questions with empty answers
  useEffect(() => {
    if (eventData.customQuestions) {
      setCustomQuestionsAnswers(
        eventData.customQuestions.map((question) => {
          return {
            questionStruct: question,
            answer: [],
          };
        })
      );
    }
  }, [eventData.customQuestions]);

  if (!customQuestionsAnswers) {
    return <Loading />;
  }

  return (
    <Grid
      container
      component={"form"}
      onSubmit={(event) => signinHandler(event, customQuestionsAnswers)}
      sx={{
        width: "100%",
        borderRadius: "0.25rem",
        mt: "1rem",
        py: "1rem",
        backgroundColor: "background.secondary",
        ...flexRowCentered,
      }}
    >
      <Grid item xs={4} sx={{ ...flexColCentered }}>
        <Typography variant="h4" sx={{ px: "1rem", mb: "1rem" }}>
          Sign in
        </Typography>
        <Button variant="contained" type="submit" sx={buttonSX}>
          Sign in for the event
        </Button>
      </Grid>
      <Grid item xs={8} sx={{ ...flexColCentered, pr: "2rem" }}>
        <EventDetailsForm
          eventData={eventData}
          customQuestionsAnswers={customQuestionsAnswers}
          setCustomQuestionsAnswers={setCustomQuestionsAnswers}
        />
      </Grid>
    </Grid>
  );
}

export default EventDetailsSignIn;
