import { ThemePalette } from "../types/styles/themePalette";

export const components = (themePalette: ThemePalette) => {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          color: themePalette.palette.text.tabs.unselected,
          "&.Mui-selected": {
            color: themePalette.palette.text.tabs.selected,
          },
          "&.Mui-disabled": {
            opacity: 0.2,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          backgroundColor: themePalette.palette.text.tabs.selected,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            "&.Mui-focused": {
              color: themePalette.palette.text.light,
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: themePalette.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: themePalette.palette.text.light,
            },
          },
        },
      },
    },
  };
};
