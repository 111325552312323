// Import React hooks
import React, { useEffect, useState, useContext } from "react";

// Import Custom hooks
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Import Context
import AuthContext from "../../context/auth-context";

// Import custom components
import NonMembers from "../../components/profile/membership/NonMembers";
import ManageMembership from "../../components/profile/membership/ManageMembership";
import PayMembershipFee from "../../components/profile/membership/PayMembershipFee";
import Loading from "../../components/ui/Loading";

// import types
import { UserType } from "../../types/models/users";
import UserRoles from "../../types/enums/userRoles";

function UserMembership() {
  const authCtx = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState<UserType>();
  const { loading, sendRequest } = useSendRequestWithToken();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await sendRequest("GET", `/profile`, {});
        const userData: UserType = response.data.user;
        setUserInfo(userData);
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkMembership = () => {
    if (
      userInfo &&
      // check if user has the role activeMember or passiveMember
      authCtx.roles.filter((role: UserRoles) =>
        [UserRoles.ACTIVEMEMBER, UserRoles.PASSIVEMEMBER].includes(role)
      ).length > 0
    ) {
      // if user is a member, check if the membership fee is paid
      if (userInfo.membershipPayed) {
        // if user is a member and the membership fee is paid, show a message that the membership fee is paid and until when it is paid
        return <ManageMembership userInfo={userInfo} />;
      } else {
        // if user is a member but the membership fee is not paid, show a message that the membership fee is not paid and a button to pay it
        return <PayMembershipFee userInfo={userInfo} />;
      }
    } else {
      // if user is not a member, show a message that the user is not a member and a button to become a member
      return <NonMembers />;
    }
  };

  return (
    <>
      {loading && <Loading />}
      {checkMembership()}
    </>
  );
}

export default UserMembership;
