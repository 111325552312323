import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSendRequest from "../../hooks/useSendRequest";
import { PostType } from "../../types/models/posts";
import { GetPostResponse } from "../../types/posts/posts";

const Post = () => {
  const [postData, setPostData] = useState<PostType>();

  const { postId } = useParams();

  const { sendRequest } = useSendRequest();

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await sendRequest("GET", `/posts/${postId}`, {});
        const responseData: GetPostResponse = response.data;
        setPostData(responseData.post);
      } catch (error) {}
    };
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h1>{postData.title}</h1>
        <h2>{postData.author}</h2>
        <div>{postData.content}</div>
      </div>
    </>
  );
};

export default Post;
