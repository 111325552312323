// import React
import React, { useContext } from "react";

// import Mui components
import { Paper, Typography, Badge } from "@mui/material";

// Imoport custom styles
import { flexRowCentered } from "../../../styles/commonStyles";

// Import context
import AuthContext from "../../../context/auth-context";
import NotificationContext from "../../../context/notification-context";
import { PopulatedChatType } from "../../../types/models/chats";

const chatSortAlgorithm = (a: PopulatedChatType, b: PopulatedChatType) => {
  const date_a = new Date(a.lastMessage);
  const date_b = new Date(b.lastMessage);

  return date_b.valueOf() - date_a.valueOf();
};

type SelectChatProps = {
  chat: PopulatedChatType;
  handleSelectChat: (event: any, chat: PopulatedChatType) => void;
};

function SelectChat(props: SelectChatProps) {
  // Set the name of the chat to the name of the other participant
  const authCtx = useContext(AuthContext);
  const otherParticipant = props.chat.participants.filter(
    (participant) => participant._id !== authCtx.userId
  );

  // check if there is a notification for this chat
  const notifications = useContext(NotificationContext);
  const notification = notifications.chatNotifications.includes(props.chat._id);

  // update the name of the chat
  props.chat.name = otherParticipant[0].firstname;

  return (
    <Paper
      onClick={(event) => {
        props.handleSelectChat(event, props.chat);
      }}
      sx={{
        ...flexRowCentered,
        width: "90%",
        mb: 1.5,
        p: 2,
        cursor: "pointer",
        position: "relative",
      }}
    >
      {notification && (
        <Badge
          sx={{ position: "absolute", right: 0, top: 0 }}
          variant="dot"
          color="error"
        />
      )}
      <Typography variant="h6">{props.chat.name}</Typography>
    </Paper>
  );
}

type SelectChatListProps = {
  chats: PopulatedChatType[];
  handleSelectChat: (event: any, chat: PopulatedChatType) => void;
};

// select chat component
function SelectChatList({ chats, handleSelectChat }: SelectChatListProps) {
  return (
    <>
      {chats && chats.length > 0 ? (
        chats
          .sort((a, b) => {
            return chatSortAlgorithm(a, b);
          })
          .map((chat) => {
            return (
              <SelectChat
                key={chat._id}
                chat={chat}
                handleSelectChat={handleSelectChat}
              />
            );
          })
      ) : (
        <Typography variant="h6"> No chats </Typography>
      )}
    </>
  );
}

export default SelectChatList;
