// Import React
import React from "react";

// Import Custom Components
import EventCard from "./EventCard";

// Import MUI Components
import { Grid } from "@mui/material";

// Import Types
import { EventType } from "../../types/models/events";

const searchFilterAlgorithm = (
  event: EventType,
  searchlist: string[],
  year: number
) => {
  let contains = true;
  searchlist.forEach((element) => {
    if (element !== "") {
      if (!event.name.toLowerCase().includes(element.toLowerCase())) {
        contains = false;
      }
    }
  });

  let date = new Date(Date.now());
  const event_date = new Date(event.eventDate);
  const event_year = event_date.getFullYear();
  return (
    event_year === year && date.valueOf() > event_date.valueOf() && contains
  );
};

const searchSortAlgorithm = (a: EventType, b: EventType) => {
  const date_a = new Date(a.eventDate);
  const date_b = new Date(b.eventDate);

  return date_b.valueOf() - date_a.valueOf();
};

type PastEventListProps = {
  events: EventType[];
  search: string[];
  year: number;
};
function PastEventList({ events, search, year }: PastEventListProps) {
  return (
    <Grid
      sx={{
        width: "100%",
        p: "1rem",
        mb: 0,
        bgcolor: "background.secondary",
      }}
      justifyContent={"center"}
      container
    >
      {events
        .filter((event) => {
          return searchFilterAlgorithm(event, search, year);
        })
        .sort((a, b) => {
          return searchSortAlgorithm(a, b);
        })
        .map((event) => {
          return (
            <Grid key={event._id} item md={8} lg={5} sx={{ m: 2 }}>
              <EventCard event={event} />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default PastEventList;
