import { useState, useRef, useEffect } from "react";

// https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom

// This hook gets dimension of react components. Mainly used for Navbars and elementes who have a fixed position to make
// other elements position themselfs in the right way

export type Dimension =
  | "x"
  | "y"
  | "width"
  | "height"
  | "top"
  | "right"
  | "bottom"
  | "left";

function useGetDimension() {
  const [dimensions, setDimensions] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const ref = useRef(null);

  useEffect(() => {
    // Function to update the dimensions based on the current element
    const updateDimensions = () => {
      // Check if an element is assigned
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setDimensions({
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
          top: rect.top,
          right: rect.right,
          bottom: rect.bottom,
          left: rect.left,
        });
      }
    };

    if (ref.current) {
      const resizeObserver = new ResizeObserver(updateDimensions);
      resizeObserver.observe(ref.current);

      // Initial dimension setting
      updateDimensions();

      // Clean up observer when the component using the hook is unmounted
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const getDimension = (dimension: Dimension): number => {
    if (!dimensions.hasOwnProperty(dimension)) {
      console.warn("Invalid dimension requested:", dimension);
      return null;
    }
    return dimensions[dimension];
  };

  return { ref, getDimension };
}

export default useGetDimension;
