import React, { useMemo, useEffect } from "react";

import io, { Socket } from "socket.io-client";
import { ChildrenProp } from "../types/props/common";
import vars from "../confidential.json";

const SocketContext = React.createContext({
  chatSocket: null as Socket,
  eventsSocket: null as Socket,
});

export function SocketContextProvider(props: ChildrenProp) {
  const chatSocket = useMemo(() => io(vars.backend_WS_URL + "/chat"), []);
  const eventsSocket = useMemo(() => io(vars.backend_WS_URL + "/events"), []);

  useEffect(() => {
    // disconnect sockets when component unmounts
    return () => {
      chatSocket.disconnect();
      eventsSocket.disconnect();
    };
  });

  return (
    <SocketContext.Provider
      value={{
        chatSocket: chatSocket,
        eventsSocket: eventsSocket,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
}

export default SocketContext;
