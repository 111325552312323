import React, { useState } from "react";
import { LoginResponse } from "../types/auth/login";
import UserRoles from "../types/enums/userRoles";
import { ChildrenProp } from "../types/props/common";

const AuthContext = React.createContext({
  isLoggedIn: false,
  // Id of user, same as the _id row in the database
  userId: "",
  // JWT token for authentification
  token: "",
  roles: [] as UserRoles[],
  login: (response: LoginResponse) => {},
  logout: () => {},
  reloadRoles: (roles: UserRoles[]) => {},
});

export function AuthContextProvider(props: ChildrenProp) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [roles, setRoles] = useState<UserRoles[]>([]);

  const reloadRolesHandler = (roles: UserRoles[]) => {
    setRoles(roles);
  };

  const loginHandler = (response: LoginResponse) => {
    setLoggedIn(true);
    setUserId(response.userId);
    setToken(response.token);
    setRoles(response.roles);
  };

  const logoutHandler = () => {
    setLoggedIn(false);
    setUserId("");
    setToken("");
    setRoles([]);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        userId: userId,
        token: token,
        roles: roles,
        login: loginHandler,
        logout: logoutHandler,
        reloadRoles: reloadRolesHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
