import React, { useEffect, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

// Import Custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Context
import SocketContext from "../../../context/socket-context";

// Import Types
import { EventSignInRequest } from "../../../types/events/Events";
import EventDetailsSignIn from "./EventDetailsTypes/EventDetailsSignIn";
import EventDetailsSignOut from "./EventDetailsTypes/EventDetailsSignOut";
import EventDetailsLogin from "./EventDetailsTypes/EventDetailsLogin";
import EventDetailsEnterWaitingList from "./EventDetailsTypes/EventDetailsEnterWaitingList";
import EventDetailsLeaveWaitingList from "./EventDetailsTypes/EventDetailsLeaveWaitingList";
import { ExtraQuestionsAnswers } from "../../../types/eventRegistration/eventRegistration";
import { EventDetailOptions } from "../../../types/enums/events";
import EventDetailsPast from "./EventDetailsTypes/EventDetailsPast";
import { GetEventDetailsResponse } from "../../../types/events/eventDetails";

type EventDetailActionsProps = {
  eventDetailsInfo: GetEventDetailsResponse;
  getEventDetailsInfo: () => void;
  type: EventDetailOptions;
};

function EventDetailActions({
  eventDetailsInfo,
  getEventDetailsInfo,
  type,
}: EventDetailActionsProps) {
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const { eventsSocket: socket } = useContext(SocketContext);
  const { loading, sendRequest } = useSendRequestWithToken();

  // sets up a listener for socket notifications
  useEffect(() => {
    // listen for socket notifications to refetch updated data
    socket.emit("JoinEventRoom", eventId);
    socket.on("ReloadEventDetails", getEventDetailsInfo);
    return () => {
      // remove listener when component unmounts
      socket.off("ReloadEventDetails", getEventDetailsInfo);
      socket.emit("LeaveEventRoom", eventId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for signing in for an event. Also for entering the waitinglist
  const signinHandler = async (
    event: React.FormEvent<HTMLFormElement>,
    customQuestionsAnswers: ExtraQuestionsAnswers[]
  ) => {
    event.preventDefault();
    try {
      const data: EventSignInRequest = {
        eventId: eventId,
        customQuestions: customQuestionsAnswers,
      };

      await sendRequest("POST", "/events/signin", data);
      socket.emit("newEventSignup/Signout", eventId);
    } catch (error) {
      console.log(error);
    }
  };

  // Handler for signing out of an event. Also for leaving the waitinglist
  const signoutHandler = async () => {
    try {
      await sendRequest("POST", `/events/signout`, { eventId });
      socket.emit("newEventSignup/Signout", eventId);
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = async (
    event: React.FormEvent<HTMLFormElement>,
    customQuestionsAnswers: ExtraQuestionsAnswers[]
  ) => {
    event.preventDefault();
    try {
      const data: EventSignInRequest = {
        eventId: eventId,
        customQuestions: customQuestionsAnswers,
      };

      await sendRequest("PUT", `/events/updateEventRegistration`, data);
      socket.emit("newEventSignup/Signout", eventId);
    } catch (error) {
      console.log(error);
    }
  };

  // Handler for redirecting to the login page before beeing able to signing in and out
  const loginRedirectHandler = () => {
    navigate("/auth", { state: { from: location }, replace: true });
  };

  switch (type) {
    case EventDetailOptions.PAST:
      return <EventDetailsPast participated={false} />;
    case EventDetailOptions.LOGIN:
      return <EventDetailsLogin loginRedirectHandler={loginRedirectHandler} />;
    case EventDetailOptions.SIGNIN:
      return (
        <EventDetailsSignIn
          eventData={eventDetailsInfo.event}
          signinHandler={signinHandler}
          loading={loading}
        />
      );
    case EventDetailOptions.SIGNOUT:
      return (
        <EventDetailsSignOut
          eventData={eventDetailsInfo.event}
          registrationInfo={eventDetailsInfo.registration}
          signoutHandler={signoutHandler}
          editHandler={editHandler}
          loading={loading}
        />
      );
    case EventDetailOptions.ENTER_WAITINGLIST:
      return (
        <EventDetailsEnterWaitingList
          eventData={eventDetailsInfo.event}
          signinHandler={signinHandler}
          loading={loading}
        />
      );
    case EventDetailOptions.LEAVE_WAITINGLIST:
      return (
        <EventDetailsLeaveWaitingList
          eventData={eventDetailsInfo.event}
          registrationInfo={eventDetailsInfo.registration}
          signoutHandler={signoutHandler}
          editHandler={editHandler}
          loading={loading}
        />
      );
  }
}

export default EventDetailActions;
