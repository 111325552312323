// Import React hooks and Components
import { Outlet } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

// Import Custom Components
import Loading from "../ui/Loading";

// Import Custom hook
import useRefreshToken from "../../hooks/useRefreshToken";

// Import context
import AuthContext from "../../context/auth-context";

// This component keeps the user logged by refreshing the token if no token is found
const PersistLogin = () => {
  const authctx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();

  useEffect(() => {
    const refreshToken = async () => {
      await refresh();
      setIsLoading(false);
    };

    // If no accesstoken is available, try refreshing
    !authctx.token ? refreshToken() : setIsLoading(false);
  }, [authctx.token, refresh]);

  return <>{isLoading ? <Loading /> : <Outlet />}</>;
};

export default PersistLogin;
