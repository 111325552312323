// Import React hooks
import React from "react";

// Import Custom hooks
import EditUserProfile from "../../components/profile/membership/EditUserProfile";


function UserManageProfile() {
  return (
    <>
      <EditUserProfile/>
    </>
  );
}

export default UserManageProfile;
