// Hooks and React components
import React from "react";
import { Routes, Route } from "react-router-dom";

// Components
import Nav from "./components/navs/mainNav/MainNav";
import RequireAuth from "./components/auth/RequireAuth";
import PersistLogin from "./components/auth/PersistLogin";
import Feedback from "./components/ui/Feedback";

// Pages
import Test from "./pages/Test";
import Home from "./pages/Home";
import About from "./pages/About";

// Import Route structures
import { EventRoutes } from "./pages/events/Events";
import { ProfileRoutes } from "./pages/profile/ProfileRoutes";
import { AdminRoutes } from "./pages/admin/AdminRoutes";
import { AuthRoutes } from "./pages/auth/AuthRoutes";
import { PostRoutes } from "./pages/blog/Blog";
import UserRoles from "./types/enums/userRoles";

// all Test# routes are for testing purposes only, and will be deleted later
function App() {
  return (
    <>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<Feedback />}>
            <Route path="/" element={<Nav />}>
              <Route index element={<Home />} />
              {EventRoutes}
              {AuthRoutes}
              {PostRoutes}
              <Route path="about" element={<About />} />
              <Route element={<RequireAuth requiredRoles={[]} />}>
                {ProfileRoutes}
              </Route>
              <Route
                element={<RequireAuth requiredRoles={[UserRoles.ADMIN]} />}
              >
                <Route path="test" element={<Test />} />
                {AdminRoutes}
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
