//  Import React hooks
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

// Import Custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import MUI Components
import { Paper } from "@mui/material";

// Import Custom Components
import Loading from "../ui/Loading";
import EventSelector from "./EventSelectors";
import UpcomingEventList from "./UpcomingEventList";
import PastEventList from "./PastEventList";
import { GetEventsResponse } from "../../types/events/Events";
import { EventType } from "../../types/models/events";

type EventListBodyProps = { search: string[] };

function EventlistBody({ search }: EventListBodyProps) {
  const { loading, sendRequest } = useSendRequest();
  const [events, setEvents] = useState<EventType[]>([]);

  const location = useLocation();

  const [minYear, setMinYear] = useState<number>();
  const [maxYear, setMaxYear] = useState<number>();
  const [nowYear] = useState<number>(new Date(Date.now()).getFullYear());

  const [year, setYear] = useState(
    location.state?.date ? new Date(location.state.date).getFullYear() : nowYear
  );

  useEffect(() => {
    const getEvents = async () => {
      try {
        const response = await sendRequest("GET", "/events", {});
        const responseData: GetEventsResponse = response.data;
        const sortedEvents = responseData.events.sort(
          (a, b) =>
            new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
        setEvents(responseData.events);
        setMinYear(new Date(sortedEvents[0].eventDate).getFullYear());
        setMaxYear(
          new Date(
            sortedEvents[sortedEvents.length - 1].eventDate
          ).getFullYear()
        );
      } catch (error) {}
    };

    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeYearHandler = useCallback(
    (action: "increase" | "decrease") => {
      if (action === "increase") {
        if (year < maxYear) {
          setYear((prevYear: number) => prevYear + 1);
        }
      } else if (action === "decrease") {
        if (year > minYear) {
          setYear((prevYear: number) => prevYear - 1);
        }
      }
    },
    [maxYear, minYear, year]
  );

  return (
    <>
      {loading && <Loading />}

      <Paper
        sx={{
          mb: 5,
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "text.dark",
          backgroundColor:
            year === nowYear ? "primary.light" : "background.secondary",
        }}
      >
        <EventSelector
          year={year}
          maxYear={maxYear}
          minYear={minYear}
          onChangeYear={changeYearHandler}
        />
        {year === nowYear && (
          <UpcomingEventList search={search} year={year} events={events} />
        )}
        <PastEventList search={search} year={year} events={events} />
      </Paper>
    </>
  );
}

export default EventlistBody;
