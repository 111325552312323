import React from "react";

// Import MUI
import { Container, Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Import framer motion animations
//import { motion, useScroll } from "framer-motion";

// Import styles
import { flexColCentered } from "../../styles/commonStyles";
import { ThemePalette } from "../../types/styles/themePalette";

function HomeFooter() {
  const theme = useTheme<ThemePalette>();

  return (
    <>
      <Box
        sx={{
          zIndex: 10,
          bgcolor: theme.palette.background.secondary,
          mt: -1,
          height: "400px",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            py: 3,
            mt: -1,
            height: "400px",
          }}
        >
          <Grid container>
            <Grid item xs={10} sx={{ ...flexColCentered, p: 1 }}></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HomeFooter;
