// Import React components and hooks
import React, { useState, useEffect } from "react";

// Import Material UI components
import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { flexRowCentered, flexColCentered } from "../../styles/commonStyles";

type ImgSliderProps = {
  imageUrls: string[];
  height: string;
  width: string;
};

function ImgSlider(props: ImgSliderProps) {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      nextImg();
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex]);

  function nextImg() {
    setImageIndex((prev) => {
      if (prev + 1 >= props.imageUrls.length) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }

  function prevImg() {
    setImageIndex((prev) => {
      if (prev - 1 < 0) {
        return props.imageUrls.length - 1;
      } else {
        return prev - 1;
      }
    });
  }

  return (
    <Box sx={{ ...flexColCentered }}>
      <Box
        sx={{
          position: "relative",
          height: props.height,
          width: props.width,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={prevImg}>
          <ArrowBackIosIcon />
        </Button>
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
          }}
        >
          {props.imageUrls.map((url, index) => (
            <Box
              component="img"
              key={index}
              src={url}
              sx={{
                translate: `${-100 * imageIndex}%`,
                transition: "translate 0.4s ease-in-out",
                width: "100%",
                objectFit: "cover",
                flexShrink: 0,
                flexGrow: 0,
              }}
            />
          ))}
        </Box>
        <Button onClick={nextImg}>
          <ArrowForwardIosIcon />
        </Button>
      </Box>
      <Box
        sx={{
          ...flexRowCentered,
          position: "relative",
          top: "-3rem",
          p: 2,
        }}
      >
        {props.imageUrls.map((_, index) => (
          <Box
            key={index}
            onClick={() => setImageIndex(index)}
            sx={{
              cursor: "pointer",
              borderRadius: "1rem",
              width: "0.9rem",
              height: "0.9rem",
              mx: 0.8,
              backgroundColor: imageIndex === index ? "primary.main" : "white",
              border: "1px solid black",
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ImgSlider;
