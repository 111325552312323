// Import React Hooks
import { useLocation, Navigate, Outlet } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

// Import Custom Hooks
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";
import useLogout from "../../hooks/useLogout";

// Import Custom Components
import Loading from "../ui/Loading";

// Import Context
import AuthContext from "../../context/auth-context";
import FeedbackContext from "../../context/feedback-context";

// Import Pages
import Error from "../../pages/Error";
import UserRoles from "../../types/enums/userRoles";
import { VerifyResponse } from "../../types/auth/verify";

type Props = { requiredRoles: UserRoles[] };

// Component that wraps any Component/Page that requires the user to be authenticated and having a certain role
const RequireAuth = ({ requiredRoles }: Props) => {
  const outletCtx = useOutletContext();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const feedbackCtx = useContext(FeedbackContext);

  const logout = useLogout();
  const { loading, sendRequest } = useSendRequestWithToken();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await sendRequest("GET", "/auth/verify", {});
        const data: VerifyResponse = response.data;
        // quickly check in backend if the loaded userId and Roles are correct (they might get set manuelly through js), if not, logout
        if (
          data.userId !== authCtx.userId &&
          !data.roles.every((el) => authCtx.roles.includes(el))
        ) {
          logout();
          feedbackCtx.showFeedback(
            "Your userId/roles dont match the data on our server. For security reasons, you're getting logged of",
            "info"
          );
        }
      } catch (error) {}
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // move rendering to a function to avoid nesting lots of trenary operations
  const render = () => {
    // If user is not loggedIn, forward him to the login page
    if (!authCtx.isLoggedIn) {
      return <Navigate to={"/auth"} state={{ from: location }} replace />;
    }

    // If user is logged in. First check if the page is accessible only for certain roles, if not, show the page. If yes, check if the user has the needed role
    if (
      requiredRoles.length === 0 ||
      authCtx.roles.filter((role) => requiredRoles.includes(role)).length > 0
    ) {
      return <Outlet context={outletCtx} />;
    }

    // If user doesnt have the needed role, return a 403 (Forbidden) Error page
    return <Error code={403} />;
  };

  return (
    <>
      {loading && <Loading />}
      {render()}
    </>
  );
};
export default RequireAuth;
