// Hooks and React Components
import React, { useState, useEffect, useCallback } from "react";
import { Route } from "react-router-dom";

// MUI Components
import { Container } from "@mui/material";

// Custom Components
import EventListBody from "../../components/events/EventListBody";
import EventSearchbar from "../../components/events/EventSearchbar";

// Pages
import EventDetails from "./EventDetails";

// Define the Routes structure here and export it to App.js to avoid cluttering
export const EventRoutes = (
  <Route path="events">
    <Route index element={<Events />} />
    <Route path=":eventId" element={<EventDetails />} />
  </Route>
);

function Events() {
  const [searchstrings, SetSearchstrings] = useState<string[]>([""]);
  const [search, setSearch] = useState<string>("");

  const searchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  useEffect(() => {
    SetSearchstrings(search.split(" "));
  }, [search]);

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <EventSearchbar search={search} onSearch={searchHandler} />
      <EventListBody search={searchstrings} />
    </Container>
  );
}

export default Events;
