import React from "react";
import { Link } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import { motion } from "framer-motion";
import { drawThenFill } from "../../../animations/motion_variants";

function TopLeft() {
  return (
    <>
      <ThemeToggle />

      <motion.svg
        viewBox="0 0 600 470"
        width="70px"
        style={{ margin: 20 }}
        initial="hidden"
        animate="visible"
      >
        <Link to="/">
          <motion.path
            d="M1450 2263 c-24 -37 -49 -83 -56 -102 -12 -35 -11 -36 12 -39 22 -3
         24 -8 24 -53 0 -77 -28 -120 -102 -162 -35 -19 -84 -56 -110 -82 -45 -44 -58
         -72 -82 -177 -3 -14 -23 -48 -43 -75 -36 -47 -37 -50 -34 -125 l2 -77 -40 -26
         c-62 -41 -72 -75 -69 -213 2 -109 0 -121 -20 -146 -11 -15 -24 -37 -27 -47 -6
         -19 -15 -20 -118 -18 l-112 1 3 -26 3 -26 -336 0 -335 0 0 -430 0 -430 1485 0
         1485 0 0 248 c0 137 -3 331 -7 430 l-6 182 -323 0 -323 0 -3 26 -3 27 -108 -2
         c-103 -2 -107 -1 -116 21 -5 13 -18 32 -30 42 -19 18 -21 29 -21 150 0 147
         -11 181 -70 211 -30 15 -30 16 -30 99 0 79 -2 85 -34 127 -32 42 -52 88 -75
         174 -13 49 -46 82 -148 149 -87 57 -113 95 -121 178 -4 46 -4 48 22 48 30 0
         31 8 12 54 -11 28 -94 156 -100 156 -1 0 -22 -30 -46 -67"
            transform="translate(0.000000,470.000000) scale(0.200000,-0.200000)"
            stroke="#ffffff"
            strokeWidth={50}
            variants={drawThenFill("#ffffff00")}
            custom={{ duration: 2, bgcolor: "#ffffff" }}
          />
        </Link>
      </motion.svg>
    </>
  );
}

export default TopLeft;
