import React, { useEffect, useState } from "react";

import { Box, Typography, Button, Grid } from "@mui/material";

import {
  flexColCentered,
  flexRowCentered,
} from "../../../../styles/commonStyles";

import { buttonSX } from "../../../../styles/eventDetailsActions";

// Types
import { EventType } from "../../../../types/models/events";
import { EventRegistrationType } from "../../../../types/models/eventRegistrations";
import EventDetailsForm from "../EventDetailsForm";
import { ExtraQuestionsAnswers } from "../../../../types/eventRegistration/eventRegistration";

type EventDetailsSignOutProps = {
  registrationInfo: EventRegistrationType;
  eventData: EventType;
  signoutHandler: () => void;
  editHandler: (
    event: React.FormEvent<HTMLFormElement>,
    customQuestionsAnswers: ExtraQuestionsAnswers[]
  ) => void;
  loading: boolean;
};

function EventDetailsSignOut({
  registrationInfo,
  eventData,
  signoutHandler,
  editHandler,
  loading,
}: EventDetailsSignOutProps) {
  const [customQuestionsAnswers, setCustomQuestionsAnswers] =
    useState<ExtraQuestionsAnswers[]>();

  const [isEditData, setEditData] = useState(false);

  useEffect(() => {
    setCustomQuestionsAnswers(registrationInfo.customQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "0.25rem",
        mt: "1rem",
        py: "1rem",
        backgroundColor: "background.secondary",
        ...flexColCentered,
      }}
    >
      <Typography variant="h4" sx={{ px: "1rem", mb: "1rem" }}>
        Your are signed in
      </Typography>
      <Grid container sx={{ ...flexRowCentered }}>
        <Grid item xs={6} sx={{ px: "2rem" }}>
          <Button
            variant="contained"
            onClick={signoutHandler}
            sx={{ ...buttonSX, width: "100%" }}
          >
            Sign out of the event
          </Button>
        </Grid>
        <Grid item xs={6} sx={{ px: "2rem" }}>
          <Button
            variant="contained"
            onClick={() => {
              setEditData(true);
            }}
            sx={{ ...buttonSX, width: "100%" }}
          >
            Edit registration data
          </Button>
        </Grid>
      </Grid>
      <Box
        component={"form"}
        onSubmit={(event) => editHandler(event, customQuestionsAnswers)}
        sx={{ ...flexColCentered, px: "2rem", width: "100%" }}
      >
        {isEditData && (
          <>
            <EventDetailsForm
              eventData={eventData}
              customQuestionsAnswers={customQuestionsAnswers}
              setCustomQuestionsAnswers={setCustomQuestionsAnswers}
            />
            <Box
              sx={{
                ...flexRowCentered,
                width: "100%",
                justifyContent: "space-around",
                py: "1rem",
              }}
            >
              <Button variant="contained" type="submit">
                Save changes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setEditData(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default EventDetailsSignOut;
