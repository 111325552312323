import React from "react";
import { Route } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import UserNav from "../../components/navs/UserNav";

import UserMembership from "./UserMembership";
import UserDashboard from "./UserDashboard";
import UserPassword from "./UserPassword";
import UserManageProfile from "./UserManageProfile";
import UserChat from "./UserChat";

export const ProfileRoutes = (
  <Route element={<UserNav />}>
    <Route path="profile">
      <Route index element={<UserDashboard />} />
      <Route path="membership" element={<UserMembership />} />
      <Route path="manage" element={<UserManageProfile />} />
      <Route path="password" element={<UserPassword />} />
      <Route path="chat" element={<UserChat />} />
    </Route>
  </Route>
);
