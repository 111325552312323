import React, { FormEvent } from "react";

// Hooks
import { useContext, useRef, useState } from "react";
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Material UI components
import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import CircularProgress from "@mui/material/CircularProgress";

// Contexts
import ThemeContext from "../../context/theme-context";
import FeedbackContext from "../../context/feedback-context";

import { flexColCentered } from "../../styles/commonStyles";

// Import types
import dayjs, { Dayjs } from "dayjs";
import { NewEventRequest } from "../../types/events/newEvent";
import ExtraQuestions from "../../components/events/eventForm/ExtraQuestions";
import { ExtraQuestion } from "../../types/events/Events";

function EventForm() {
  const { loading, sendRequest } = useSendRequestWithToken();
  const ctx = useContext(ThemeContext);
  const feedbackCtx = useContext(FeedbackContext);

  // For date inputs a state is used because MUI doesnt support ref
  const [flyerImage, setFlyerImage] = useState<File | null>(null);
  const [eventImages, setEventImages] = useState<FileList | null>(null);
  const [customQuestions, setCustomQuestions] = useState<ExtraQuestion[]>([]);

  const [eventDate, setEventDate] = useState<Dayjs>(dayjs(Date.now()));
  const [openForRegistrationDate, setOpenForRegistrationDate] = useState<Dayjs>(
    dayjs(Date.now())
  );

  // Bunch of ref objects for the inputs
  const nameInputRef = useRef<HTMLInputElement>();
  const locationInputRef = useRef<HTMLInputElement>();
  const feeInputRef = useRef<HTMLInputElement>();
  const limitInputRef = useRef<HTMLInputElement>();
  const descriptionInputRef = useRef<HTMLInputElement>();
  const openForInputRef = useRef<HTMLInputElement>();

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();

    const data: NewEventRequest = {
      name: nameInputRef.current.value,
      location: locationInputRef.current.value,
      fee: feeInputRef.current.value,
      limit: limitInputRef.current.value,
      eventDate: eventDate.toDate(),
      openForRegistrationDate: openForRegistrationDate.toDate(),
      openFor: openForInputRef.current.value,
      description: descriptionInputRef.current.value,
      flyerImage: flyerImage,
      customQuestions: customQuestions,
    };
    if (eventImages) {
      data.eventImages = eventImages;
    }

    try {
      await sendRequest("POST", `/events`, data, "multipart/form-data");

      feedbackCtx.showFeedback("Event added succesfully", "success", 5000);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Adding event failed: " + error_msg, "error");
    }
  };

  const flyerImageChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFlyerImage(e.target.files[0]);
    }
  };

  const eventImagesChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setEventImages(e.target.files);
    }
  };

  return (
    <Box sx={{ ...flexColCentered }}>
      <Grid
        component="form"
        onSubmit={submitHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "90%",
          bgcolor: `background.${ctx.theme}`,
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Create new Event
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={nameInputRef}
            sx={{ width: "100%" }}
            label="Name"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={locationInputRef}
            sx={{ width: "100%" }}
            label="Location"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={feeInputRef}
            sx={{ width: "100%" }}
            label="Fee"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={limitInputRef}
            sx={{ width: "100%" }}
            label="Limit"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={openForInputRef}
            sx={{ width: "100%" }}
            label="Open for"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimeField"]}>
              <DateTimeField
                required
                value={eventDate}
                onChange={(newValue) => setEventDate(newValue)}
                format="DD-MM-YYYY HH:mm"
                label="Date of the Event"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimeField"]}>
              <DateTimeField
                required
                value={openForRegistrationDate}
                onChange={(newValue) => setOpenForRegistrationDate(newValue)}
                format="DD-MM-YYYY HH:mm"
                label="Date of opening the registration form"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={3}
            inputRef={descriptionInputRef}
            sx={{ width: "100%" }}
            label="description"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Flyer image</Typography>
          <Input type="file" required onChange={flyerImageChangeHandler} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Event image</Typography>
          <Input
            type="file"
            inputProps={{ multiple: true }}
            onChange={eventImagesChangeHandler}
          />
        </Grid>
        <Divider sx={{ width: "100%", py: "1rem" }} />
        <ExtraQuestions
          extraQuestions={customQuestions}
          setExtraQuestions={setCustomQuestions}
        />
        <Grid
          sx={{ display: "flex", justifyContent: "center", mt: "1rem" }}
          item
          xs={12}
        >
          {!loading ? (
            <Button sx={{ width: "80%" }} variant="contained" type="submit">
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventForm;
