// import React
import React, { useState, useEffect, useContext } from "react";

// import Mui components
import { Paper, Grid, Typography, Divider } from "@mui/material";

// import custom components
import Loading from "../../components/ui/Loading";
import Chat from "../../components/profile/chat/Chat";
import CreateNewChat from "../../components/profile/chat/CreateNewChat";
import SelectChatList from "../../components/profile/chat/SelectChatList";

// import custom hooks
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Imoport custom styles
import { flexColCentered, flexRowCentered } from "../../styles/commonStyles";

import SocketContext from "../../context/socket-context";
import { GetPopulatedChatsResponse } from "../../types/chats/chats";
import { PopulatedChatType } from "../../types/models/chats";

function UserChat() {
  const [chats, setChats] = useState<PopulatedChatType[]>();
  const [activeChat, setActiveChat] = useState<PopulatedChatType>();
  const { loading, sendRequest } = useSendRequestWithToken();

  const { chatSocket: socket } = useContext(SocketContext);

  // Select Chat
  const handleSelectChat = (_: any, chat: PopulatedChatType) => {
    setActiveChat(chat);
  };

  const handleCloseChat = () => {
    setActiveChat(null);
  };

  // get chats
  useEffect(() => {
    const getChats = async () => {
      try {
        const response = await sendRequest("get", "/chat", {});
        const responseData: GetPopulatedChatsResponse = response.data;
        setChats(responseData.chats);
      } catch (error) {
        console.log(error);
      }
    };

    // get initial chat messages
    getChats();

    // fetch chats whenever new chat is created or a Message is received, TODO: can be done more efficiently
    socket.on("newMessageReceived", getChats);
    socket.on("newChatIsCreated", getChats);
    return () => {
      socket.off("newMessageReceived", getChats);
      socket.off("newChatIsCreated", getChats);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Grid container sx={{ alignItems: "start" }}>
        <Grid item xs={8} sx={{ width: "100%", p: 1, height: "80vh" }}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              ...flexRowCentered,
              overflow: "hidden",
            }}
          >
            {activeChat ? (
              <Chat activeChat={activeChat} handleCloseChat={handleCloseChat} />
            ) : (
              <Typography variant="h4"> No chat selected </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={4} sx={{ width: "100%", ...flexColCentered, p: 1 }}>
          {chats && (
            <SelectChatList chats={chats} handleSelectChat={handleSelectChat} />
          )}

          <Divider sx={{ width: "90%", my: 2 }} />
          <CreateNewChat />
        </Grid>
      </Grid>
    </>
  );
}

export default UserChat;
