import React from "react";
import { Route } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPW from "./ForgotPW";
import Verify from "../../components/auth/Verify";

import ResetPW from "./ResetPW";

// Define the Routes structure here and export it to App.js to avoid cluttering
export const AuthRoutes = (
  <Route path="auth">
    <Route index element={<Login />} />
    <Route path="register" element={<Register />} />
    <Route path="forgotpw" element={<ForgotPW />} />
    <Route path="resetpw/:string" element={<ResetPW />} />
    <Route path="verify/:string" element={<Verify />} />
  </Route>
);
