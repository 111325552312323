/* eslint-disable react-hooks/exhaustive-deps */
// Import React hooks and Components
import React from "react";
import { Link } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";

// Import Material UI components
import { Container, Button, Grid, Typography } from "@mui/material";

// Import Animation lib
import { motion, useAnimate } from "framer-motion";
import {
  drawThenFill,
  scaleOnCompletion,
} from "../../animations/motion_variants";

// Import Material UI Icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { flexRowCentered } from "../../styles/commonStyles";
import { useTheme } from "@mui/material/styles";
import { ThemePalette } from "../../types/styles/themePalette";

function HomeIntroduction() {
  const theme = useTheme<ThemePalette>();
  const [firstLoad, setFirstLoad] = useState(true);

  // we need two useAnimate hooks, one for each part of the logo for which the color changes (the outline and the inside of the A)
  const [scope1, animate1] = useAnimate();
  const [scope2, animate2] = useAnimate();

  // this part is particullary for changing the color of the logo when the theme changes, since its animated with framer motion, we need to use the useAnimate hook
  useEffect(() => {
    // this if statement is to prevent the function from running on the first load
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    animate1(
      scope1.current,
      {
        fill: theme.palette.background.secondary,
      },
      { duration: 0 }
    );
    animate2(
      scope2.current,
      {
        fill: theme.palette.background.secondary,
      },
      { duration: 0 }
    );
  }, [theme]);

  // Function which return a big SVG of the msza logo outline animated, TODO: move to animations folder
  const drawLogoSVG = useCallback((theme: ThemePalette) => {
    return (
      <motion.svg
        viewBox="0 -5 1350.000000 1060.000000"
        width="100%"
        initial="hidden"
        animate="visible"
        variants={scaleOnCompletion}
        custom={3}
      >
        <motion.g
          transform="translate(0.000000,2808.000000) scale(0.100000,-0.100000)"
          stroke="#000000"
          //stroke={theme.palette.text.primary}
          strokeWidth="40"
        >
          <motion.path
            ref={scope1}
            d="M6705 28061 c-38 -75 -68 -130 -85 -156 -11 -16 -23 -37 -27 -45 -3
            -8 -26 -41 -50 -72 -24 -31 -43 -60 -43 -65 0 -4 -25 -37 -55 -72 -30 -36 -58
            -74 -61 -85 -4 -10 -26 -51 -50 -90 -45 -74 -100 -227 -106 -293 l-3 -38 105
            -5 105 -5 3 -175 c4 -230 -4 -266 -107 -490 -34 -73 -148 -200 -181 -200 -5 0
            -10 -4 -10 -9 0 -5 -12 -14 -27 -20 -36 -14 -117 -59 -168 -94 -22 -15 -44
            -27 -48 -27 -5 0 -29 -13 -54 -30 -24 -16 -48 -30 -53 -30 -12 0 -170 -117
            -206 -154 -17 -17 -53 -49 -79 -71 -51 -42 -205 -213 -205 -228 0 -4 -11 -23
            -24 -40 -29 -38 -55 -106 -65 -167 -4 -25 -11 -52 -16 -60 -5 -8 -12 -32 -16
            -55 -4 -22 -13 -60 -20 -85 -6 -25 -17 -67 -24 -95 -44 -171 -55 -202 -94
            -275 -5 -8 -16 -30 -25 -48 -19 -36 -87 -114 -153 -176 -24 -21 -43 -44 -43
            -50 0 -5 -13 -24 -29 -41 -15 -16 -33 -50 -39 -75 -12 -54 -10 -243 6 -431 11
            -136 5 -236 -17 -277 -15 -29 -72 -76 -112 -92 -68 -28 -229 -151 -229 -176 0
            -3 -14 -25 -31 -50 -17 -25 -42 -70 -57 -102 l-27 -57 0 -581 0 -581 -25 -26
            c-14 -15 -29 -27 -35 -27 -19 0 -82 -65 -102 -105 -34 -67 -53 -115 -53 -133
            0 -25 -42 -73 -65 -73 -11 0 -230 2 -488 5 l-467 5 -14 -22 c-11 -17 -12 -44
            -6 -117 4 -52 4 -102 -1 -111 -8 -15 -129 -17 -1491 -15 l-1483 1 -5 -1930
            c-3 -1062 -2 -1934 2 -1939 6 -8 2468 -16 4153 -14 292 0 2477 0 4857 -1 2696
            -1 4329 2 4332 8 4 5 7 631 7 1391 1 1252 -1 1383 -15 1399 -14 16 -16 76 -16
            549 0 293 -3 532 -7 532 -5 0 -666 0 -1470 0 l-1463 1 -6 26 c-4 14 -4 68 -1
            119 7 127 7 128 -132 119 -111 -7 -202 -8 -486 -5 -93 0 -208 -1 -255 -3 -110
            -5 -140 4 -141 46 0 18 -11 52 -24 77 -13 25 -29 55 -34 67 -11 24 -93 109
            -148 155 l-33 26 0 579 c-1 492 -3 581 -15 597 -8 11 -15 29 -15 41 0 12 -5
            26 -11 32 -12 12 -32 44 -48 78 -9 21 -125 145 -135 145 -3 0 -24 12 -48 28
            -73 46 -82 52 -103 61 -39 17 -70 60 -89 122 -16 54 -18 85 -13 199 3 74 11
            185 18 246 11 99 11 120 -5 190 -9 43 -23 86 -31 94 -8 9 -15 21 -15 26 0 5
            -30 38 -66 74 -37 36 -86 94 -110 130 -52 79 -140 262 -160 333 -25 90 -46
            180 -54 237 -18 122 -71 253 -131 320 -12 14 -32 41 -45 62 -13 20 -35 45 -50
            55 -14 10 -45 36 -68 59 -42 41 -240 194 -252 194 -3 0 -23 13 -43 29 -20 16
            -65 46 -100 67 -35 22 -75 47 -90 56 -43 28 -66 41 -133 75 -35 18 -79 48 -98
            67 -78 76 -159 217 -197 346 -49 164 -53 197 -43 312 5 61 7 121 4 132 -3 12
            1 29 9 40 13 18 58 22 162 13 30 -2 30 -2 27 48 -3 58 -45 199 -73 245 -5 8
            -13 24 -18 35 -15 34 -154 237 -196 288 -38 46 -84 118 -105 166 -6 13 -21 38
            -33 55 -30 41 -45 68 -53 89 -8 23 -27 22 -39 -2z "
            variants={drawThenFill(theme.palette.background.default)}
            custom={{
              duration: 3,
              bgcolor: theme.palette.background.secondary,
            }}
          />
          <motion.path
            d="M6150 21164 c-19 -2 -87 -8 -150 -14 -115 -11 -239 -32 -295 -51 -16
            -6 -43 -15 -60 -19 -157 -44 -316 -150 -401 -265 -47 -65 -74 -115 -109 -205
            -25 -64 -26 -316 -2 -354 10 -14 17 -31 17 -39 0 -22 38 -88 85 -149 52 -67
            113 -114 205 -159 36 -18 77 -38 92 -45 14 -8 35 -14 45 -14 10 0 23 -4 29
            -10 5 -5 30 -14 54 -19 25 -6 72 -16 105 -24 33 -8 80 -19 105 -25 25 -6 75
            -18 112 -26 36 -9 80 -16 96 -16 17 0 43 -5 59 -11 15 -6 55 -14 88 -19 70 -9
            158 -26 235 -45 30 -8 67 -16 81 -19 71 -15 168 -114 169 -172 0 -41 -40 -113
            -83 -146 -62 -48 -101 -64 -210 -83 -85 -16 -111 -16 -185 -6 -174 24 -247 42
            -274 69 -7 7 -16 12 -21 12 -18 0 -80 56 -103 92 -13 21 -24 50 -24 64 l0 27
            -207 -7 c-115 -4 -296 -9 -402 -12 -122 -3 -197 -8 -202 -15 -9 -14 21 -139
            49 -209 6 -14 13 -32 16 -40 4 -8 15 -28 26 -45 11 -16 24 -39 29 -50 15 -32
            117 -141 169 -178 74 -54 246 -137 285 -137 12 -1 33 -7 47 -15 14 -8 40 -14
            58 -15 18 0 43 -5 54 -11 75 -40 790 -46 928 -7 19 5 62 13 95 18 72 10 176
            38 190 51 5 5 18 9 28 9 10 0 31 6 45 14 15 8 45 23 67 33 59 29 105 57 105
            66 0 4 6 7 13 7 16 0 184 171 197 202 5 12 14 30 18 38 44 78 62 133 77 241
            32 227 -45 422 -215 546 -72 52 -173 100 -270 126 -30 9 -80 23 -111 31 -31 9
            -64 16 -75 16 -10 0 -48 6 -84 15 -36 8 -101 21 -145 30 -44 9 -98 21 -120 26
            -22 6 -62 14 -90 18 -69 11 -222 49 -290 73 -16 5 -41 13 -55 16 -33 7 -89 43
            -119 76 -52 55 -24 163 52 203 84 44 127 52 287 52 164 0 212 -9 292 -56 45
            -27 98 -97 98 -131 l0 -25 73 8 c132 13 279 21 549 31 208 7 206 6 188 74 -55
            214 -187 379 -375 468 -90 43 -209 82 -250 82 -16 1 -57 7 -90 15 -33 8 -85
            15 -115 15 -30 0 -82 4 -115 9 -68 11 -312 21 -365 15z "
            variants={drawThenFill(theme.palette.background.default)}
            custom={{ duration: 3, bgcolor: "#ffffff" }}
          />
          <motion.path
            d="M1325 21148 c-155 -19 -366 -74 -425 -109 -8 -5 -22 -12 -30 -15 -18
            -7 -93 -44 -122 -61 -81 -45 -211 -186 -244 -263 -3 -8 -12 -28 -19 -45 -41
            -98 -40 -62 -40 -983 l0 -883 389 0 c214 0 392 3 395 8 3 4 4 388 1 853 -6
            941 -9 885 64 944 82 65 180 88 401 94 164 4 297 -12 372 -44 17 -8 36 -14 41
            -14 6 0 31 -20 56 -45 l47 -45 -1 -867 c0 -478 0 -872 0 -878 0 -6 137 -10
            390 -10 253 0 390 4 390 10 0 6 0 400 0 878 l-1 867 47 45 c25 25 52 45 61 45
            8 0 24 6 36 12 50 28 154 41 342 42 142 0 208 -4 260 -16 131 -30 200 -74 228
            -143 9 -23 11 -233 7 -875 -3 -465 -2 -849 1 -853 3 -4 182 -9 397 -10 l392
            -2 0 880 c-1 744 -3 884 -15 905 -8 14 -14 32 -15 41 -1 48 -74 166 -157 254
            -84 90 -254 181 -423 228 -186 52 -276 61 -625 61 -355 1 -433 -6 -630 -55
            -22 -6 -56 -14 -75 -19 -52 -12 -98 -30 -162 -61 -57 -28 -58 -29 -95 -12 -21
            10 -45 22 -53 29 -8 6 -26 14 -40 17 -14 3 -38 10 -55 15 -75 26 -113 36 -155
            43 -25 5 -70 13 -100 18 -170 31 -656 42 -835 19z "
            variants={drawThenFill(theme.palette.background.default)}
            custom={{ duration: 3, bgcolor: "#ffffff" }}
          />
          <motion.path
            d="M10555 21070 c-8 -14 -8 -511 1 -518 16 -12 1273 3 1323 17 28 7 48
            8 54 2 13 -13 -19 -53 -63 -79 -19 -11 -91 -69 -160 -127 -69 -59 -145 -123
            -169 -143 -24 -20 -49 -42 -56 -49 -6 -6 -41 -35 -76 -64 -35 -29 -71 -60 -80
            -68 -9 -9 -37 -34 -64 -56 -42 -35 -234 -198 -305 -259 -14 -11 -59 -50 -101
            -86 -42 -36 -97 -83 -124 -105 -26 -22 -54 -46 -61 -53 -8 -7 -42 -36 -76 -65
            -81 -66 -115 -96 -148 -127 l-27 -25 5 -237 4 -238 1287 -2 c707 -2 1287 -2
            1289 -1 6 2 5 534 -1 539 -10 10 -1212 3 -1302 -7 -49 -6 -101 -12 -114 -12
            -53 -4 -30 31 65 101 45 32 143 114 179 149 6 6 41 35 76 64 35 28 69 57 74
            63 6 7 123 108 260 226 138 118 252 217 255 221 3 3 34 30 70 59 36 29 70 58
            75 64 8 10 350 303 364 313 6 4 8 503 2 503 -475 4 -2454 3 -2456 0z "
            variants={drawThenFill(theme.palette.background.default)}
            custom={{ duration: 3, bgcolor: "#ffffff" }}
          />
          <motion.path
            d="M8484 21053 c-9 -29 -35 -94 -59 -148 -13 -27 -33 -75 -45 -105 -12
            -30 -28 -68 -35 -85 -34 -77 -78 -173 -91 -198 -8 -16 -14 -33 -14 -38 0 -9
            -49 -121 -75 -172 -8 -16 -15 -33 -15 -38 0 -5 -14 -39 -31 -76 -60 -135 -91
            -206 -135 -310 -8 -18 -21 -49 -29 -68 -9 -19 -22 -50 -31 -70 -8 -19 -21 -50
            -30 -70 -27 -65 -43 -100 -73 -168 -17 -37 -31 -71 -31 -76 0 -5 -6 -22 -14
            -38 -8 -15 -21 -44 -30 -63 -8 -19 -29 -65 -45 -103 -17 -37 -31 -70 -31 -74
            0 -3 -12 -31 -26 -62 -14 -31 -35 -76 -46 -101 -11 -25 -36 -78 -56 -118 -31
            -64 -34 -74 -21 -87 11 -12 22 -13 49 -5 22 7 187 10 432 9 218 -1 401 2 406
            5 5 3 12 18 15 33 2 16 10 44 17 63 7 19 18 51 25 70 16 45 43 137 50 173 l5
            27 445 0 c417 0 445 -1 445 -17 0 -10 7 -31 15 -47 8 -15 15 -36 15 -45 0 -18
            25 -98 46 -149 8 -18 14 -43 14 -55 0 -13 6 -31 13 -41 12 -16 44 -17 447 -17
            392 0 435 2 438 16 2 9 -3 24 -10 33 -8 9 -22 37 -32 62 -10 25 -24 59 -31 75
            -7 17 -20 50 -30 75 -9 25 -21 52 -26 60 -5 8 -12 22 -15 30 -3 8 -11 29 -19
            45 -7 17 -23 55 -35 85 -12 30 -33 80 -47 110 -27 61 -45 102 -67 155 -9 20
            -22 51 -30 70 -9 20 -23 52 -32 73 -9 20 -22 52 -30 70 -8 17 -20 47 -28 65
            -20 46 -44 102 -60 140 -7 17 -20 48 -29 67 -9 19 -30 65 -46 103 -17 37 -31
            73 -31 81 0 7 -7 19 -15 26 -8 7 -15 20 -15 29 0 10 -7 26 -15 37 -8 10 -15
            25 -15 31 0 7 -14 43 -31 80 -16 38 -36 83 -44 101 -8 18 -22 49 -31 70 -9 20
            -22 51 -28 67 -23 55 -58 137 -91 210 -20 45 -35 79 -61 145 -10 25 -22 51
            -27 58 -7 9 -114 12 -474 12 -433 0 -464 -1 -469 -17z"
            variants={drawThenFill(theme.palette.background.default)}
            custom={{ duration: 3, bgcolor: "#ffffff" }}
          />
          <motion.path
            ref={scope2}
            d="M8985 20420C8988 20414 8995 
            20389 9000 20362C9014 20285 9031 20226 9041 20216C9046 20210 9050 20198 9050 20188C9050 20179 9056 20156
            9064 20138C9081 20096 9099 20044 9110 20005C9115 19989 9128 19950 9140 19920C9152 19890 9165 19851 9169 
            19835C9173 19818 9182 19796 9188 19785C9231 19705 9240 19677 9229 19664C9223 19656 9214 19653 9209 19656C9204
            19659 9087 19661 8950 19661C8813 19661 8698 19664 8696 19669C8693 19673 8704 19704 8721 19738C8737 19772 8750 
            19807 8750 19815C8750 19823 8754 19838 8760 19848C8771 19866 8796 19937 8810 19985C8814 20002 8823 20029 8829 
            20045C8866 20148 8893 20229 8897 20255C8900 20272 8906 20290 8911 20296C8916 20302 8923 20329 8926 20356C8933
            20413 8967 20450 8985 20420Z"
            variants={drawThenFill(theme.palette.background.default)}
            custom={{
              duration: 3,
              bgcolor: theme.palette.background.secondary,
            }}
          />
        </motion.g>
      </motion.svg>
    );
  }, []);

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid
        container
        direction={"row"}
        sx={{
          pb: "5rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Show the logo*/}
        <Grid item xs={12} md={6} sx={{ ...flexRowCentered, p: 1 }}>
          {drawLogoSVG(theme)}
        </Grid>
        {/* Text to present outselfs */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold" }}>
            Who are we?
          </Typography>
          <Typography>
            The Muslim Students Association Zurich (MSAZ) aims to encourage
            exchange between Muslim students, represent their needs and serve as
            a contact point for questions and concerns regarding student life in
            Zurich. The MSAZ organizes different events and welcomes everyone
            regardless of their ethnic origin, religious or ideological
            convictions.
          </Typography>
          <Button
            component={Link}
            to="/about"
            variant="contained"
            sx={{ mt: 4, background: theme.palette.button.default_bg.main }}
            endIcon={<ArrowRightIcon />}
          >
            For more infos about us
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeIntroduction;
