// Hooks
import React, { useContext, useRef } from "react";
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Material UI components
import { Box, Button, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Contexts
import ThemeContext from "../../context/theme-context";
import FeedbackContext from "../../context/feedback-context";

import { flexColCentered } from "../../styles/commonStyles";

function AdminNewPost() {
  const { loading, sendRequest } = useSendRequestWithToken();
  const ctx = useContext(ThemeContext);
  const feedbackCtx = useContext(FeedbackContext);

  // Bunch of ref objects for the inputs
  const titleInputRef = useRef<HTMLInputElement>();
  const contentInputRef = useRef<HTMLInputElement>();

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      title: titleInputRef.current.value,
      content: contentInputRef.current.value,
    };
    try {
      await sendRequest("POST", `/posts`, data);
      feedbackCtx.showFeedback("Post added succesfully", "success", 5000);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Adding post failed: " + error_msg, "error");
    }
  };

  return (
    <Box sx={{ ...flexColCentered }}>
      <Grid
        component="form"
        onSubmit={submitHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "80%",
          bgcolor: `background.${ctx.theme}`,
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Create new Post
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={titleInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Title"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            inputRef={contentInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Content"
            variant="outlined"
            required
          />
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12}>
          {!loading ? (
            <Button sx={{ width: "80%" }} type="submit">
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminNewPost;
