/* eslint-disable react-hooks/exhaustive-deps */
// import React
import React, { useState, useEffect, useRef, useContext } from "react";

// Import Animation lib
import { motion } from "framer-motion";

// import Mui components
import {
  Grid,
  Box,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

// import custom components
import Loading from "../../ui/Loading";

// import custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// import Context
import AuthContext from "../../../context/auth-context";
import SocketContext from "../../../context/socket-context";
import NotificationContext from "../../../context/notification-context";

// Imoport custom styles
import { flexRowCentered } from "../../../styles/commonStyles";
import {
  ChatMessageType,
  ChatType,
  PopulatedChatType,
} from "../../../types/models/chats";
import { GetChatResponse } from "../../../types/chats/chats";

type ChatMessageProps = { chatMessage: ChatMessageType };

function ChatMessage({ chatMessage }: ChatMessageProps) {
  const myMsgStyle = { bgcolor: "#aabbaa" };

  const otherMsgStyle = { bgcolor: "#aaaaaa" };

  const authCtx = useContext(AuthContext);
  const sender =
    JSON.stringify(chatMessage.userID) === JSON.stringify(authCtx.userId)
      ? "you"
      : "other";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: sender === "you" ? "start" : "end",
        my: 1,
      }}
    >
      <motion.div
        initial={{ opacity: 0, x: sender === "you" ? -50 : 50 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <Paper
          sx={Object.assign(sender === "you" ? myMsgStyle : otherMsgStyle, {
            p: 1,
          })}
        >
          <Typography variant="h6">{chatMessage.content}</Typography>
        </Paper>
      </motion.div>
    </Box>
  );
}

type ChatProps = { activeChat: PopulatedChatType; handleCloseChat: () => void };

// chat component, will be its own components
function Chat({ activeChat, handleCloseChat }: ChatProps) {
  const [activeChatData, setActiveChatData] = useState<ChatType>();
  const { loading, sendRequest } = useSendRequestWithToken();
  const messageInputRef = useRef<HTMLInputElement>();

  const { chatSocket: socket } = useContext(SocketContext);
  const notifications = useContext(NotificationContext);

  // Send message
  const handleSendMessage = async () => {
    const data = {
      content: messageInputRef.current.value,
    };
    messageInputRef.current.value = "";
    try {
      await sendRequest("post", `/chat/${activeChat._id}/sendMessage`, data);
      socket.emit("newMessageSent", activeChat._id);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      console.log(error_msg);
    }
  };

  // when chat is opened, remove the notification
  useEffect(() => {
    notifications.removeChatNotification(activeChat?._id);
  }, []);

  // use effect to get chat data and add socket listeners
  useEffect(() => {
    // get messages from chat
    const getMessages = async () => {
      try {
        const response = await sendRequest(
          "get",
          `/chat/${activeChat?._id}`,
          {}
        );
        const responseData: GetChatResponse = response.data;
        setActiveChatData(responseData.chat);
      } catch (error) {
        console.log(error);
      }
    };

    getMessages();

    // listen for socket notifications to refetch updated data
    socket.on("newMessageReceived", getMessages);

    return () => {
      // remove socket listener when component unmounts or active chat changes
      socket.off("newMessageReceived", getMessages);
    };
  }, [activeChat]);

  return (
    <>
      {loading && <Loading />}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            bgcolor: "primary.light",
            p: 3,
            position: "relative",
            ...flexRowCentered,
          }}
        >
          <IconButton
            sx={{ position: "absolute", left: 25, top: 25 }}
            onClick={handleCloseChat}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4"> {activeChat.name} </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            px: 4,
            overflow: "scroll",
          }}
        >
          {activeChatData && activeChatData.messages.length > 0 ? (
            activeChatData.messages.map((chatMessage: ChatMessageType) => {
              return (
                <ChatMessage
                  key={chatMessage.userID + "" + chatMessage.timestamp}
                  chatMessage={chatMessage}
                />
              );
            })
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              No messages
            </Typography>
          )}
        </Box>
        <Grid
          container
          sx={{
            bgcolor: "primary.light",
            ...flexRowCentered,
            py: 3,
          }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TextField
              sx={{ width: "100%", bgcolor: "white", borderRadius: 1 }}
              inputRef={messageInputRef}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...flexRowCentered }}>
            <IconButton onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Chat;
