import { useState } from "react";
import axios from "../services/axios";

const useSendRequest = () => {
  const [loading, setLoading] = useState(false);

  const sendRequest = async <T,>(
    method: string,
    url: string,
    data: T,
    withCredentials: boolean = false
  ) => {
    const timeout = 3000;
    try {
      setLoading(true);
      const response = await axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: withCredentials,
        timeout: timeout,
        data: data,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    sendRequest,
  };
};

export default useSendRequest;
