// Import React Components
import React from "react";
import { Link } from "react-router-dom";

function AdminEvents() {
  return (
    <div>
      <p> Still under construction ! </p>
      <Link to={"/admin/events/newevent"}>
        add new event (Functional but looks like crap)
      </Link>
    </div>
  );
}

export default AdminEvents;
