// Import React hooks
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// Import custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Animation lib
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimate,
  useInView,
} from "framer-motion";

// Import Material UI components
import { Container, Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Import some common styler
import { flexRowCentered, flexColCentered } from "../../styles/commonStyles";
import { ThemePalette } from "../../types/styles/themePalette";

function HomeUsers() {
  const [numUsers, setNumUsers] = useState(0);
  const { sendRequest } = useSendRequest();
  const ref = useRef();
  const [, animate] = useAnimate();
  const theme = useTheme<ThemePalette>();

  // Some hooks provided by framer motion for animating counting numbers
  const countUsers = useMotionValue(0);
  const roundedUsers = useTransform(countUsers, Math.round);
  const isInView = useInView(ref, { amount: 0.7 });

  // Use Effect function to fetch some data that will get displayed
  useEffect(() => {
    const getEvents = async () => {
      try {
        const usersResponse = await sendRequest("GET", "/users/active_users", {});
        setNumUsers(usersResponse.data.user_count);
      } catch (error) {
        console.log("error: ", error);
      }
    };

    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is used for animating the numbers as soon as the section is in View
  useEffect(() => {
    if (isInView) {
      const UsersAnimation = animate(countUsers, numUsers, {
        duration: 3,
        ease: "easeOut",
      });
      return UsersAnimation.stop;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }} ref={ref}>
      <Grid
        container
        sx={{
          ...flexRowCentered,
          py: "5rem",
        }}
      >
        <Grid item xs={10} md={6} sx={{ px: 3, ...flexColCentered }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
            # Members
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            style={{
              marginBottom: "2rem",
              fontSize: "4rem",
              fontWeight: "bold",
              color: theme.palette.button.default_bg.main,
            }}
          >
            {roundedUsers}
          </motion.div>
          <Typography sx={{ mb: 4 }}>
            As a passive member you support us with a financial contribution and
            thus become a member of the association. You will receive
            notifications about all our upcoming events and benefit from a
            special reduced price at any of our events that charges an entrance
            fee. As an active member, you enjoy the same benefits as passive
            members. Additionally: you can suggest and implement your own idea
            or project, you are entitled to vote at the general assembly and you
            can run for a position on the MSAZ board!
          </Typography>
          <Button
            component={Link}
            to="/profile/membership"
            variant="contained"
            sx={{ mt: 4, background: theme.palette.button.default_bg.main }}
          >
            Join us !
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeUsers;
