// Import React hooks
import React, { useRef, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Import custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Material UI Components
import { Box, Button, Grid, TextField } from "@mui/material";

// Import custom components
import Loading from "../../components/ui/Loading";

// Import Context
import FeedbackContext from "../../context/feedback-context";

// Import utils
import { wait } from "../../utils/helper_functions";

function ResetPW() {
  // get the verification string from the url
  const { string } = useParams();
  const navigate = useNavigate();

  const feedbackCtx = useContext(FeedbackContext);
  const [error, setError] = useState(null);
  const { loading, sendRequest } = useSendRequest();

  const passwordInputRef = useRef<HTMLInputElement>();
  const passwordConfirmInputRef = useRef<HTMLInputElement>();

  const updatePasswordHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      password: passwordInputRef.current.value,
      verificationString: string,
    };

    // check if the password and the confirmation match
    if (
      passwordInputRef.current.value !== passwordConfirmInputRef.current.value
    ) {
      feedbackCtx.showFeedback("Passwords do not match", "error");
      setError("Passwords do not match");
      return;
    }

    try {
      await sendRequest("put", `/auth/resetpw/${string}`, data);
      setError(null);
      feedbackCtx.showFeedback("Password updated successfully", "success");
      await wait(1000);
      navigate(`/auth`, { replace: true });
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback(
        "Updating password failed: " + error_msg,
        "error"
      );
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid
        component="form"
        onSubmit={updatePasswordHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "60%",
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Update password
          </Box>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Box
              component="h5"
              sx={{
                m: 0,
                p: "1rem",
                width: "100%",
                textAlign: "center",
                color: "rgba(255,0,0,0.8)",
                backgroundColor: "rgba(255,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              {error}
            </Box>
          )}
        </Grid>
        <Grid item xs={7}>
          <TextField
            inputRef={passwordInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            inputRef={passwordConfirmInputRef}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Confirm Password"
            type="password"
            variant="outlined"
            required
          />
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
          item
          xs={12}
        >
          <Button variant="contained" sx={{ width: "80%" }} type="submit">
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPW;
