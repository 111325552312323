// Import React hooks
import React, { useContext, useState, useRef } from "react";

// Import custom hooks
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";

// Import Material UI Components
import { Box, Button, Grid, TextField } from "@mui/material";

// Import custom components
import Loading from "../../components/ui/Loading";

// Import Context
import FeedbackContext from "../../context/feedback-context";

import { flexColCentered } from "../../styles/commonStyles";

function UserPassword() {
  const feedbackCtx = useContext(FeedbackContext);
  const [error, setError] = useState<string>();
  const { loading, sendRequest } = useSendRequestWithToken();

  const oldPasswordInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();
  const passwordConfirmInputRef = useRef<HTMLInputElement>();

  const updatePasswordHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      oldPassword: oldPasswordInputRef.current.value,
      newPassword: passwordInputRef.current.value,
    };

    // check if the password and the confirmation match
    if (
      passwordInputRef.current.value !== passwordConfirmInputRef.current.value
    ) {
      feedbackCtx.showFeedback("Passwords do not match", "error");
      setError("Passwords do not match");
      return;
    }

    try {
      await sendRequest("put", `/auth/changepw`, data);
      setError(null);
      feedbackCtx.showFeedback("Password updated successfully", "success");
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback(
        "Updating password failed: " + error_msg,
        "error"
      );
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Box sx={{ ...flexColCentered }}>
        <Grid
          component="form"
          onSubmit={updatePasswordHandler}
          container
          justifyContent="center"
          spacing={2}
          sx={{
            width: "80%",
            p: 2,
            mt: 5,
          }}
        >
          <Grid item xs={12}>
            {error && (
              <Box
                component="h5"
                sx={{
                  m: 0,
                  p: "1rem",
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,0,0,0.8)",
                  backgroundColor: "rgba(255,0,0,0.1)",
                  borderRadius: "5px",
                }}
              >
                {error}
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            <TextField
              inputRef={oldPasswordInputRef}
              sx={{ width: "100%", mb: 2 }}
              id="outlined-basic"
              label="Old Password"
              type="password"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              inputRef={passwordInputRef}
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Password"
              type="password"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              inputRef={passwordConfirmInputRef}
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Confirm Password"
              type="password"
              variant="outlined"
              required
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
            item
            xs={12}
          >
            <Button variant="contained" sx={{ width: "50%" }} type="submit">
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default UserPassword;
