import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";

import tabs_conf from "./mainNav.json";

type PageTabsProps = {
  TabValues: (type: "pages" | "auth") => string | boolean;
  tabsStyle: { height: string };
};
function PageTabs({ TabValues, tabsStyle }: PageTabsProps) {
  return (
    <Tabs value={TabValues("pages")} textColor="inherit" centered>
      {tabs_conf.pageTabs.map((tab: any) => {
        return (
          <Tab
            disabled={tab.disabled}
            component={Link}
            key={tab.value}
            label={tab.label}
            to={tab.url}
            value={tab.value}
            sx={tabsStyle}
          />
        );
      })}
    </Tabs>
  );
}

export default PageTabs;
