import React from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  IconButton,
  Typography,
  Chip,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";

import { flexRowCentered, flexColCentered } from "../../../styles/commonStyles";
import { ExtraQuestion } from "../../../types/events/Events";
import { ExtraQuestionsTypeEnum } from "../../../types/enums/eventRegistration";

type ExtraQuestionsProps = {
  extraQuestions: ExtraQuestion[];
  setExtraQuestions: React.Dispatch<React.SetStateAction<ExtraQuestion[]>>;
};
function ExtraQuestions({
  extraQuestions,
  setExtraQuestions,
}: ExtraQuestionsProps) {
  return (
    <Box sx={{ ...flexColCentered, width: "100%" }}>
      <Typography sx={{ pt: "1.5rem", pb: "0.5rem" }} variant="h5">
        Extra Custom Fields
      </Typography>
      {extraQuestions.map((question, index) => (
        <Grid
          key={index}
          container
          item
          sx={{ ...flexRowCentered, pt: "1.5rem", width: "100%" }}
          columns={17}
        >
          <Grid item xs={1} sx={{ px: "0.5rem" }}>
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                setExtraQuestions((prev) => {
                  const newQuestions = [...prev];
                  newQuestions.splice(index, 1);
                  return newQuestions;
                });
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3} sx={{ px: "0.5rem" }}>
            <Autocomplete
              sx={{ width: "100%" }}
              options={["Text Answer", "Single Select", "Multi Select"]}
              value={
                question.type === "textfield"
                  ? "Text Answer"
                  : question.type === "singleSelect"
                  ? "Single Select"
                  : "Multi Select"
              }
              onChange={(event, newValue) => {
                setExtraQuestions((prev) => {
                  const newQuestions = [...prev];
                  let typedNewValue: ExtraQuestionsTypeEnum;
                  switch (newValue) {
                    case "Text Answer":
                      typedNewValue = ExtraQuestionsTypeEnum.TEXTFIELD;
                      break;
                    case "Single Select":
                      typedNewValue = ExtraQuestionsTypeEnum.SINGLESELECT;
                      break;
                    default:
                      typedNewValue = ExtraQuestionsTypeEnum.MULTISELECT;
                  }
                  newQuestions[index].type = typedNewValue;
                  return newQuestions;
                });
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Type"
                  placeholder="type"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={
              question.type === "singleSelect" ||
              question.type === "multiSelect"
                ? 4
                : 12
            }
            sx={{ px: "0.5rem" }}
          >
            <TextField
              sx={{ width: "100%" }}
              required
              value={question.question}
              onChange={(event) => {
                setExtraQuestions((prev) => {
                  const newQuestions = [...prev];
                  newQuestions[index].question = event.target.value;
                  return newQuestions;
                });
              }}
              label="Key"
              placeholder="Key"
            />
          </Grid>
          {(question.type === "singleSelect" ||
            question.type === "multiSelect") && (
            <Grid item xs={8} sx={{ px: "0.5rem" }}>
              <Autocomplete
                multiple
                options={[]}
                value={question.options || []}
                onChange={(event, newValue) => {
                  setExtraQuestions((prev) => {
                    const newQuestions = [...prev];
                    newQuestions[index].options = newValue;
                    return newQuestions;
                  });
                }}
                freeSolo
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="options"
                    placeholder="options"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={1} sx={{ px: "0.5rem" }}>
            <FormControlLabel
              labelPlacement="top"
              label="Required"
              control={
                <Switch
                  onClick={() => {
                    setExtraQuestions((prev) => {
                      const newQuestions = [...prev];
                      newQuestions[index].required =
                        !newQuestions[index].required;
                      return newQuestions;
                    });
                  }}
                  checked={question.required}
                />
              }
            />
          </Grid>
        </Grid>
      ))}
      <IconButton
        sx={{ mt: "1.5rem" }}
        onClick={() => {
          setExtraQuestions((prev) => [
            ...prev,
            {
              type: ExtraQuestionsTypeEnum.TEXTFIELD,
              question: "",
              required: true,
            },
          ]);
        }}
      >
        <Add fontSize="large" />
      </IconButton>
    </Box>
  );
}

export default ExtraQuestions;
