// Import React
import React from "react";
import { Outlet, useLocation, Link, useOutletContext } from "react-router-dom";

// Material UI Components
import { AppBar, Box, Tabs, Tab } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorageIcon from "@mui/icons-material/Storage";
import Person2Icon from "@mui/icons-material/Person2";
import TocIcon from "@mui/icons-material/Toc";
import PaymentIcon from "@mui/icons-material/Payment";
import EventIcon from "@mui/icons-material/Event";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

// Import Custom hooks
import useGetDimension from "../../hooks/useGetDimension";

// Import types
import { MainNavOutletCtxType } from "../../types/navs/mainNav";

function AdminNav() {
  const outletCtx = useOutletContext<MainNavOutletCtxType>();
  const location = useLocation();
  const path = location.pathname;

  // Custom Hook to get dimension of Nav to calculate the margin needed for the pages
  const { ref, getDimension } = useGetDimension();

  const leftMargin = getDimension("width") + 2 * getDimension("left");
  const leftOffset = getDimension("left");

  // Map react router routes to MUI tabs values. These funtions are used to sync React Router with MUI Tabs (bidirectional)
  // IMPORTNT: When adding new tabs, these functions should be updated aswell!
  const pagesTabValues = () => {
    if (path === `/admin`) {
      return "dashboard";
    }
    if (path === `/admin/content`) {
      return "content";
    }
    if (path === `/admin/posts`) {
      return "posts";
    }
    if (path.includes(`/admin/members`)) {
      return "members";
    }
    if (path.includes(`/admin/events`)) {
      return "events";
    }

    return false;
  };

  // Navigationbar for big screen size
  const BigScreen = () => {
    const renderTabs = () => {
      return [
        <Tab
          key="dashboard"
          component={Link}
          to={`/admin`}
          label="Dashboard"
          value={"dashboard"}
          icon={<DashboardIcon />}
        />,
        <Tab
          key="content"
          component={Link}
          to={`/admin/posts`}
          label="Manage Content"
          value={"posts"}
          icon={<TocIcon />}
        />,
        <Tab
          key="members"
          component={Link}
          to={`/admin/members`}
          label="Manage Members"
          value={"members"}
          icon={<Person2Icon />}
        />,
        <Tab
          disabled
          key="payments"
          component={Link}
          to={`/admin/payment`}
          label="Manage Payments"
          value={"tbd"}
          icon={<PaymentIcon />}
        />,
        <Tab
          key="events"
          component={Link}
          to={`/admin/events`}
          label="Manage Events"
          value={"events"}
          icon={<EventIcon />}
        />,
        <Tab
          disabled
          key="invoice"
          component={Link}
          to={`/admin/invoice`}
          label="Invoice"
          value={"tbd"}
          icon={<ChatBubbleOutlineIcon />}
        />,
        <Tab
          disabled
          key="data"
          component={Link}
          to={`/admin/data`}
          label="Database"
          value={"tbd"}
          icon={<StorageIcon />}
        />,
      ];
    };

    return (
      <Tabs
        orientation="vertical"
        value={pagesTabValues()}
        textColor="inherit"
        indicatorColor="secondary"
        centered
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {renderTabs()}
      </Tabs>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          width: "18%",
          top: `${outletCtx.topMargin}px`,
          left: "2%",
          borderRadius: "0.25rem",
          backgroundColor: "primary.main",
        }}
        ref={ref}
        position="fixed"
      >
        {BigScreen()}
      </AppBar>
      <Box sx={{ pl: `${leftMargin}px`, pr: `${leftOffset}px`, width: "100%" }}>
        <Outlet />
      </Box>
    </>
  );
}

export default AdminNav;
