import React, { useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";

import useSendRequest from "../../hooks/useSendRequest";

import Post from "./Post";
import { PostType } from "../../types/models/posts";
import { GetPostsResponse } from "../../types/posts/posts";

export const PostRoutes = (
  <Route path="blog">
    <Route index element={<Blog />} />
    <Route path=":postId" element={<Post />} />
  </Route>
);

function Blog() {
  const { sendRequest } = useSendRequest();
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await sendRequest("GET", "/posts/", {});
        const responseData: GetPostsResponse = response.data;
        setPosts(responseData.posts);
      } catch (error) {
        console.error("Fehler beim Abrufen der Posts: ", error);
      }
    };
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h1>Blog</h1>
        {posts.map((post) => (
          <div key={post._id}>
            <h2>{post.title}</h2>
            <p>{post.author}</p>
            <p>{post.content}</p>
            <Link to={`/blog/${post._id}`}>Read more</Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default Blog;
