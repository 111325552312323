import React from "react";

function AdminDashboard() {
  return (
    <div>
      <p> Dashboard Still under construction ! </p>
    </div>
  );
}

export default AdminDashboard;
