import axios from "../services/axios";
import { useContext } from "react";
import AuthContext from "../context/auth-context";
import { LoginResponse } from "../types/auth/login";

const useRefreshToken = () => {
  const authctx = useContext(AuthContext);

  const refresh = async () => {
    try {
      // get refreshed token
      const response = await axios.get("/auth/refresh", {
        withCredentials: true,
      });
      const responseData: LoginResponse = response.data;
      // login again with new token
      authctx.login(responseData);
      // return new token
      return responseData.token;
    } catch (error) {}
  };

  return refresh;
};

export default useRefreshToken;
