import React from "react";
import { useLocation } from "react-router-dom";

// Import MUI
import { Grid } from "@mui/material";

//Import types
import { Dimension } from "../../../hooks/useGetDimension";

import tabs_conf from "./mainNav.json";
import TopLeft from "./Logo";
import PageTabs from "./PageTabs";
import AuthTabs from "./AuthTabs";

type BigScreenProps = { getDimension: (dimension: Dimension) => number };

function BigScreen({ getDimension }: BigScreenProps) {
  const location = useLocation();

  const path = location.pathname;

  const tabsStyle = {
    height: `${getDimension("height")}px`,
  };

  // Map react router routes to MUI tabs values. This funtion is used to sync React Router routes with MUI Tabs (bidirectional)
  const TabValues = (type: "pages" | "auth"): string | boolean => {
    const tabs = type === "pages" ? tabs_conf.pageTabs : tabs_conf.authTabs;
    for (let tab of tabs) {
      if (tab.exactPath) {
        if (path === tab.url) {
          return tab.value;
        }
      } else {
        if (path.includes(tab.url)) {
          return tab.value;
        }
      }
    }
    return false;
  };

  return (
    <>
      <Grid container spacing={0} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TopLeft />
        </Grid>
        <Grid item xs={6} sx={{ color: "text.primary" }}>
          <PageTabs TabValues={TabValues} tabsStyle={tabsStyle} />
        </Grid>
        <Grid item xs={3} sx={{ color: "text.primary" }}>
          <AuthTabs TabValues={TabValues} tabsStyle={tabsStyle} />
        </Grid>
      </Grid>
    </>
  );
}

export default BigScreen;
