// Import React Hooks
import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";

// Import Custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Custom Components
import Loading from "../ui/Loading";

const Verify = () => {
  const { string } = useParams();
  const { loading, sendRequest } = useSendRequest();

  useEffect(() => {
    const verify = async () => {
      const data = {
        verificationString: string,
      };

      try {
        await sendRequest("POST", "/auth/verify", data);
      } catch (error) {
        console.log(error);
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Navigate to={"/auth"} replace />
    </>
  );
};

export default Verify;
