import React from "react";

import { Box, Typography, Button } from "@mui/material";

import { flexColCentered } from "../../../../styles/commonStyles";
import { buttonSX } from "../../../../styles/eventDetailsActions";

type EventDetailsLoginProps = {
  loginRedirectHandler: () => void;
};

function EventDetailsLogin({ loginRedirectHandler }: EventDetailsLoginProps) {
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "0.25rem",
        mt: "1rem",
        py: "1rem",
        backgroundColor: "background.secondary",
        ...flexColCentered,
      }}
    >
      <Typography variant="h4" sx={{ px: "1rem", mb: "1rem" }}>
        Sign in
      </Typography>
      <Button variant="contained" onClick={loginRedirectHandler} sx={buttonSX}>
        login to register
      </Button>
    </Box>
  );
}

export default EventDetailsLogin;
