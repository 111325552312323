// Import React Components
import { darken, lighten, styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import useSendRequestWithToken from "../../hooks/useSendRequestWithToken";


import { DataGrid, GridCellParams, GridColDef, GridFilterItem, GridFilterModel, GridFilterOperator, GridRowClassNameParams, GridRowSelectionModel, getGridBooleanOperators } from '@mui/x-data-grid';
import { UserType } from "../../types/models/users";

import { Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemIcon, ListItemText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import UserIcon from '@mui/icons-material/Person';


import StudyLevels from "../../types/enums/studyLevels";
import UserRoles from "../../types/enums/userRoles";


export interface CustomUserType {
  id: number;
  mongo_id: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  gender: string;
  studyLevel: StudyLevels;
  roles: UserRoles[];
  phoneNumber: string;
  uni: string;
  faculty: string;
  registerDate: Date;
  verified: boolean;
  membershipPayed: boolean;
}

function convertUserType(sources: UserType[]): CustomUserType[] {
  return sources.map((source, index) => ({
    id: index + 1,
    mongo_id: source._id,
    firstname: source.firstname,
    lastname: source.lastname,
    email: source.email,
    password: source.password,
    gender: source.gender,
    studyLevel: source.studyLevel,
    roles: source.roles,
    phoneNumber: source.phoneNumber,
    uni: source.uni,
    faculty: source.faculty,
    registerDate: new Date(source.registerDate),
    verified: source.verified,
    membershipPayed: source.membershipPayed,
  }));
}


interface YesNoConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onYesClick: () => void;
  users: CustomUserType[]; // Assuming users is an array of strings (usernames or IDs)
}

const YesNoConfirmDialog: React.FC<YesNoConfirmDialogProps> = ({ open, onClose, onYesClick, users }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to proceed deleting the following user(s)?
        </DialogContentText>
        <List dense>
          {users.map((user, index) => (
            <ListItem key={index} dense>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={`${user.firstname} ${user.lastname}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onYesClick} color="primary">
          Yes
        </Button>
        <Button onClick={onClose} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}


const DataTable: React.FC<{}> = () => {

  // Filters
  function generateFilterModel(role: UserRoles): GridFilterModel {
    return { items: [{ id: 1, field: 'roles', operator: 'containsArrayValue', value: role },], };
  }

  const filterModel_all: GridFilterModel = { items: [], };
  type FilterModelEnum = {
    [key: string]: GridFilterModel;
  };
  const FilterModels: FilterModelEnum = {
    ALL: filterModel_all,
    BOARD: generateFilterModel(UserRoles.BOARD),
    ACTIVE: generateFilterModel(UserRoles.ACTIVEMEMBER),
    PASSIVE: generateFilterModel(UserRoles.PASSIVEMEMBER),
    UNPAID: { items: [{ id: 5, field: 'membershipPayed', operator: 'is', value: 'false' },] },
    UNVERIFIED: { items: [{ id: 0, field: 'verified', operator: 'is', value: 'false' },] },
  };

  const [users, setUsers] = useState<CustomUserType[]>([]);
  const [selectedFilter, setSelectedFilter] = React.useState<string>('ALL');
  const filterModel = FilterModels[selectedFilter];
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<string>(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

  const [adminUserId, setAdminUserId] = useState<string>("");
  const { sendRequest: getUsersRequest } = useSendRequestWithToken();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  // Confirm Dialog
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<CustomUserType[]>([]);
  const { sendRequest: deleteRequest } = useSendRequestWithToken();

  useEffect(() => {
    const getMembers = async () => {
      try {
        const response = await getUsersRequest("GET", `/users`, {});
        setAdminUserId(response.data.userid);
        setUsers(convertUserType(response.data.users));
      } catch (error) {
        console.log(error);
      }
    };
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to render an array of strings as Chips
  const renderTags = (tags: UserRoles[] | undefined): JSX.Element => {
    return (
      <>{tags.map((tag, index) => (<Chip key={index} label={tag} size="small" style={{ margin: '1px' }} />))}</>
    );
  };

  const containsArrayValueOperator: GridFilterOperator = {
    label: 'Contains Array Value',
    value: 'containsArrayValue',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) return null;

      return (params: GridCellParams): boolean => {
        return (params.value as String[]).includes(filterItem.value)
      };
    },
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'firstname', headerName: 'First name', width: 150 },
    { field: 'lastname', headerName: 'Last name', width: 150 },
    { field: 'email', headerName: 'E-Mail', type: 'string', width: 300 },
    { field: 'membershipPayed', headerName: 'Paid', type: 'boolean', headerAlign:"center", width: 100, filterOperators: getGridBooleanOperators() },
    { field: 'verified', headerName: '✅', type: 'boolean', width: 100, filterOperators: getGridBooleanOperators() },
    { field: 'roles', headerName: 'Roles', width: 300, filterOperators: [containsArrayValueOperator], sortable: false, renderCell: (params) => renderTags(params.value) },
    { field: 'gender', headerName: 'Gender', type: 'string', width: 80 },
    { field: 'uni', headerName: 'Uni', type: 'string', width: 120 },
    { field: 'faculty', headerName: 'Faculty', type: 'string', width: 180 },
    { field: 'registerDate', headerName: 'Registered', type: 'dateTime', width: 180 },
  ];

  const getRowId = (row: CustomUserType): string => row.mongo_id;
  const getBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  const getSelectedBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  const getSelectedHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({

    // Add styles for custom-row-active
    '& .custom-row-user-admin': {
      backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(theme.palette.info.main, theme.palette.mode,),
        },
      },
    },
    // Add styles for custom-row-inactive
    '& .custom-row-user-board': {
      backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
      },
    },
  }));

  // Actions
  const openConfirmDialogDelete = () => { setOpenConfirmDialog(true); };
  const deleteUserHandler = async () => {
    try {
      // Todo: implement
      await Promise.all(selectedUsers.map(async (user) => {
        await deleteRequest("delete", `/profile?state_userid=${user.mongo_id}`, {});
      }));
      setUsers(users.filter(item => !selectedUsers.includes(item)));
      setSelectedUsers([]);
      setRowSelectionModel([]);
      // window.location.replace(response.data.session.url);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      console.log(error_msg);
    }
  };

  // Filters
  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newFilter: string | null) => {
    if (newFilter !== null) setSelectedFilter(newFilter);
  };

  return (
    <>
      <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ paddingRight: 5, paddingBottom: 2 }}>
        <Button variant="contained" disabled={!canEdit} component={Link} to='/admin/members/edit' state={{ userid: editedUser }}>Edit</Button>
        <Button variant="contained" disabled={!canDelete} onClick={() => { openConfirmDialogDelete(); }} >{'Delete'}</Button>
      </ButtonGroup>

      <ToggleButtonGroup
        color="primary"
        value={selectedFilter}
        onChange={handleFilterChange}
        aria-label="outlined Platform"
        size="small"
        exclusive
      >
        <ToggleButton value={"ALL"}>All</ToggleButton>
        <ToggleButton value={"BOARD"}>Board</ToggleButton>
        <ToggleButton value={"ACTIVE"}>Active members</ToggleButton>
        <ToggleButton value={"PASSIVE"}>Passive members</ToggleButton>
        <ToggleButton value={"UNPAID"}>Unpaid membership</ToggleButton>
        <ToggleButton value={"UNVERIFIED"}>Unverified users</ToggleButton>
      </ToggleButtonGroup>

      {/* Delete Dialog */}
      <YesNoConfirmDialog
        open={openConfirmDialog}
        onYesClick={() => { deleteUserHandler(); setOpenConfirmDialog(false); }}
        onClose={() => { setOpenConfirmDialog(false); }}
        users={selectedUsers}
      />

      {/* DATA GRID */}
      <StyledDataGrid
        rows={users}
        columns={columns}
        initialState={
          {
            pagination: { paginationModel: { pageSize: 25 }, },
            columns: {columnVisibilityModel: {verified: false, faculty: false},},
          }
        }
        pageSizeOptions={[25, 50, 100]}
        rowHeight={30}
        getRowId={getRowId}
        getRowClassName={(params: GridRowClassNameParams<any>) => {
          if (params.id === adminUserId) return 'custom-row-user-admin';
          else if (params.row.roles.includes(UserRoles.BOARD)) return 'custom-row-user-board';
        }}
        checkboxSelection
        isRowSelectable={(params) => { return params.id !== adminUserId; }}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const hasAdmin = selectedIDs.has(adminUserId);
          const canDelete = !hasAdmin && selectedIDs.size > 0;
          const canEdit = !hasAdmin && selectedIDs.size === 1;
          const selectedRows = users.filter((row) => selectedIDs.has(row.mongo_id));
          setCanDelete(canDelete);
          setCanEdit(canEdit);
          setRowSelectionModel(ids);
          setSelectedUsers(selectedRows);
          if (canEdit) setEditedUser(Array.from(selectedIDs)[0] as string);
        }}
        rowSelectionModel={rowSelectionModel}
        filterModel={filterModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        keepNonExistentRowsSelected
      />
    </>
  );
}


function AdminMembers() {

  return (
    <div>
      <h1>User Overview</h1>
      <DataTable />
    </div>
  );
}

export default AdminMembers;
