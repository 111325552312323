export const drawThenFill = (initFill: string) => {
  return {
    hidden: { pathLength: 0, fill: initFill },
    visible: (i: { duration: number; bgcolor: string }) => {
      const { duration, bgcolor } = i;
      return {
        pathLength: 1,
        fill: bgcolor,

        transition: {
          pathLength: { duration: duration, ease: "easeInOut" },
          fill: { delay: duration },
        },
      };
    },
  };
};

export const scaleOnCompletion = {
  hidden: { scale: 0.8 },
  visible: (i: number) => {
    return { scale: 1, transition: { scale: { delay: i } } };
  },
};

export const buttonAnimation = {
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 },
};
