// Import React hooks
import React from "react";

// Import Custom Components
import EventCard from "./EventCard";

// Import Material UI Components
import { Box, Grid, Typography } from "@mui/material";

import { flexColCentered, flexRowCentered } from "../../styles/commonStyles";
// Import Types
import { EventType } from "../../types/models/events";

// Filter the displayed Events depending on the searchbar
const searchFilterAlgorithm = (
  event: EventType,
  searchlist: string[],
  year: number
) => {
  let contains = true;
  searchlist.forEach((element) => {
    if (element !== "") {
      if (!event.name.toLowerCase().includes(element.toLowerCase())) {
        contains = false;
      }
    }
  });

  let date = new Date(Date.now());
  const event_date = new Date(event.eventDate);
  const event_year = event_date.getFullYear();
  return (
    event_year === year && date.valueOf() < event_date.valueOf() && contains
  );
};

// Sort Events by date in ascending order
const searchSortAlgorithm = (a: EventType, b: EventType) => {
  const date_a = new Date(a.eventDate);
  const date_b = new Date(b.eventDate);

  return date_b.valueOf() - date_a.valueOf();
};

type UpcomingEventListProps = {
  events: EventType[];
  search: string[];
  year: number;
};
function UpcomingEventList({ events, search, year }: UpcomingEventListProps) {
  let filteredEvents = events
    .filter((event) => {
      return searchFilterAlgorithm(event, search, year);
    })
    .sort((a, b) => {
      return searchSortAlgorithm(a, b);
    });

  // get number of events to render the size of the event card
  let numEvents = filteredEvents.length;

  return (
    <>
      <Box
        sx={{
          ...flexColCentered,
          width: "100%",
          padding: "0.5rem 0 2rem 0",
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
            py: "1rem",
          }}
        >
          Upcoming Events !
        </Typography>
        <Grid
          container
          sx={{
            ...flexRowCentered,
            padding: "1rem",
          }}
        >
          {numEvents > 0 ? (
            filteredEvents.map((event) => {
              return (
                <Grid key={event._id} item xs={8} md={numEvents === 1 ? 8 : 5}>
                  <EventCard event={event} />
                </Grid>
              );
            })
          ) : (
            <Typography
              variant="h4"
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                borderRadius: "2rem",
                padding: "1rem 2rem",
              }}
            >
              There are currently no upcoming events :(
            </Typography>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default UpcomingEventList;
