// Import React hooks
import React, { useEffect, useState } from "react";
// Import Material UI Components
import { Box } from "@mui/material";
import Loading from "../components/ui/Loading";
import { Card, CardContent, Typography } from "@mui/material";
import SvgIcon from '@mui/material/SvgIcon';


// Import Custom hooks
import useSendRequestWithToken from "../hooks/useSendRequestWithToken";
import { UserType } from "../types/models/users";

function BoardCard(board_member: UserType, role: string) {
  return (
    <Card style={{ margin: '10px', width: '100%' }}>
      <CardContent>
        <Typography variant="h6">{board_member.firstname} {board_member.lastname}</Typography>
        <Typography variant="body2">{role}</Typography>
      </CardContent>
    </Card>
  );
}



function About() {
  const { loading, sendRequest } = useSendRequestWithToken();
  const [boardInfo, setBoardInfo] = useState<any>();
  useEffect(() => {
    const getBoard = async () => {
      try {
        const response = await sendRequest("GET", `/users/board`, {});
        setBoardInfo(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const load_board = () => {
    if (
      boardInfo
    ) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            {boardInfo.president && BoardCard(boardInfo.president, "President")}
            {boardInfo.vicePresident && BoardCard(boardInfo.vicePresident, "Vice-President")}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {boardInfo.generalsecretary && BoardCard(boardInfo.generalsecretary, "General Secretary")}
            {boardInfo.finance && BoardCard(boardInfo.finance, "Finance")}
            {boardInfo.communications && BoardCard(boardInfo.communications, "Communications")}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {boardInfo.socialRessort && BoardCard(boardInfo.socialRessort, "Social Ressort")}
            {boardInfo.membersResponsible && BoardCard(boardInfo.membersResponsible, "Members' responsible")}
            {boardInfo.IT && BoardCard(boardInfo.IT, "IT responsible")}
          </div>
        </div>
      );
    } else {
      return (
        <Card variant="outlined">
          Fail
        </Card>
      );
    }
  };

  const msaz_pillars = () => {
    const msaz_pillars_text = [
      'Exchange between Muslim students',
      'Contact point for Muslim students',
      "Represents Muslim students at Zurich's universities",
      'Promotion of interfaith dialogue',
      'Religious, cultural, and social events'
    ];
    return (
      <Box display="flex" justifyContent="space-between" width="100%" marginTop={1}>
        {msaz_pillars_text.map((text, index) => (
          <Box key={index} display="flex" flexDirection="column" alignItems="center" width="19%">
            <Box width="100%" height={150} bgcolor="#739FD8" display="flex" justifyContent="center" alignItems="center" >
              <Typography variant="subtitle2" color="white" style={{ textAlign: 'center', margin: '3px' }}>
                {text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: {
            xs: '80%',
            sm: '80%',
            md: '60%',
            lg: '60%',
            xl: '60%',
          },
          mt: 5,
          whoAreWeIsInView: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <h1>About Us</h1>
          <p>
            The MSAZ (Muslim Students Association Zurich) was established in 2012 as a student association at the UZH (University of Zurich), the ETH (Swiss Federal Institute of Technology) and the ZHAW (Zürich University of Applied Sciences).
          </p>
          <p>
            As a community platform, we aim to encourage exchange between Muslim students, represent their needs and serve as a contact point for their questions and concerns regarding student life in Zurich. The MSAZ organizes different events and welcomes everyone regardless of their ethnic origin, religious or ideological convictions. We are politically as well as financially independent from other organizations in Switzerland or abroad.
          </p>

          <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
            {/* Large triangle */}
            <SvgIcon style={{ width: '100%', height: 'auto' }} viewBox="0 0 100 20">
              <polygon points="0,20 100,20 50,0" fill="#355C8C" />
              <text x="50%" y="60%" textAnchor="middle" dominantBaseline="middle" fill="#fff" fontSize="5"> MSAZ </text>
            </SvgIcon>
            {msaz_pillars()}
          </Box>

          <h1>Structure</h1>
          <p>
            The association is composed of active and passive members, as well as the board members which can be elected at the annual General Assembly. The active and passive members are the pillars of the association and support it financially (passive members) and through the complete organization or support of events (active members). The board consists of students from different disciplines of UZH, ETH and ZHAW.
          </p>
          <p>Since the last General Assemby of March 2023, the Board has been occupied by:</p>
          <div>
            {loading && <Loading />}
            {load_board()}
          </div>
        </div>
      </Box>
    </>
  );
}

export default About;
