// Import React Components
import { Outlet } from "react-router-dom";
import React, { useContext } from "react";

// Import Material UI components
import { Snackbar, Alert } from "@mui/material";

// Import Feedback Context
import FeedbackContext from "../../context/feedback-context";

function Feedback() {
  const feedbackCtx = useContext(FeedbackContext);

  const handleCloseFeedbackBar = (event: Event, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    feedbackCtx.closeFeedbackHandler();
  };

  return (
    <>
      <Outlet />
      <Snackbar
        open={feedbackCtx.open}
        autoHideDuration={feedbackCtx.duration}
        onClose={handleCloseFeedbackBar}
        sx={{ width: "30%" }}
      >
        <Alert
          elevation={5}
          onClose={() => feedbackCtx.closeFeedbackHandler()}
          severity={feedbackCtx.severity}
          sx={{ width: "100%" }}
        >
          {feedbackCtx.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Feedback;
