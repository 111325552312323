import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Badge, Tab, Tabs } from "@mui/material";

//Import custom hooks
import useLogout from "../../../hooks/useLogout";

// Import Context
import AuthContext from "../../../context/auth-context";
import NotificationContext from "../../../context/notification-context";

import UserRoles from "../../../types/enums/userRoles";

type AuthTabsProps = {
  TabValues: (type: "pages" | "auth") => string | boolean;
  tabsStyle: { height: string };
};

function AuthTabs({ TabValues, tabsStyle }: AuthTabsProps) {
  const logout = useLogout();
  const authCtx = useContext(AuthContext);
  const { chatNotifications } = useContext(NotificationContext);

  const renderOptionsTabs = () => {
    if (!authCtx.isLoggedIn) {
      return (
        <Tab
          key="login"
          component={Link}
          label="Login"
          to="/auth"
          value="auth"
          sx={tabsStyle}
        />
      );
    }
    let tabArr = [
      <Tab
        key="profile"
        component={Link}
        label={
          chatNotifications && chatNotifications.length > 0 ? (
            <Badge variant="dot" color="error">
              Profile
            </Badge>
          ) : (
            "Profile"
          )
        }
        to={`/profile`}
        value="profile"
        sx={tabsStyle}
      />,
      <Tab key="logout" label="Logout" onClick={logout} sx={tabsStyle} />,
    ];
    if (authCtx.roles.includes(UserRoles.ADMIN)) {
      tabArr.push(
        <Tab
          key="admin"
          component={Link}
          label="Admin"
          to={`/admin`}
          value="admin"
          sx={tabsStyle}
        />
      );
    }
    return tabArr;
  };

  return (
    <Tabs value={TabValues("auth")} textColor="inherit" centered>
      {renderOptionsTabs()}
    </Tabs>
  );
}

export default AuthTabs;
