import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { EventType } from "../../../types/models/events";
import { ExtraQuestionsAnswers } from "../../../types/eventRegistration/eventRegistration";

type EventDetailsFormProps = {
  eventData: EventType;
  customQuestionsAnswers: ExtraQuestionsAnswers[];
  setCustomQuestionsAnswers: React.Dispatch<
    React.SetStateAction<ExtraQuestionsAnswers[]>
  >;
};

function EventDetailsForm({
  eventData,
  customQuestionsAnswers,
  setCustomQuestionsAnswers,
}: EventDetailsFormProps) {
  return (
    <>
      {eventData.customQuestions.map((question, index) => {
        if (question.type === "singleSelect") {
          return (
            <Autocomplete
              key={index}
              sx={{ width: "100%", my: "1rem" }}
              options={question.options}
              value={customQuestionsAnswers[index].answer[0] || null}
              onChange={(event, newValue) => {
                setCustomQuestionsAnswers((prev) => {
                  const newQuestionsAnswers = [...prev];
                  newQuestionsAnswers[index].answer = [newValue];
                  return newQuestionsAnswers;
                });
              }}
              renderInput={(params) => (
                <TextField
                  required={question.required}
                  {...params}
                  label={question.question}
                  placeholder={question.question}
                />
              )}
            />
          );
        } else if (question.type === "multiSelect") {
          return (
            <Autocomplete
              key={index}
              multiple
              sx={{ width: "100%", my: "1rem" }}
              options={question.options}
              value={customQuestionsAnswers[index].answer}
              onChange={(event, newValue) => {
                setCustomQuestionsAnswers((prev) => {
                  const newQuestionsAnswers = [...prev];
                  newQuestionsAnswers[index].answer = newValue;
                  return newQuestionsAnswers;
                });
              }}
              renderInput={(params) => (
                <TextField
                  required={question.required}
                  {...params}
                  label={question.question}
                  inputProps={{
                    ...params.inputProps,
                    required: customQuestionsAnswers[index].answer.length === 0,
                  }}
                  placeholder={question.question}
                />
              )}
            />
          );
        }
        return (
          <TextField
            key={index}
            sx={{ width: "100%", my: "1rem" }}
            value={customQuestionsAnswers[index].answer}
            onChange={(event) => {
              setCustomQuestionsAnswers((prev) => {
                const newQuestionsAnswers = [...prev];
                newQuestionsAnswers[index].answer = [event.target.value];
                return newQuestionsAnswers;
              });
            }}
            label={question.question}
            placeholder={question.question}
            required={question.required}
          />
        );
      })}
    </>
  );
}

export default EventDetailsForm;
