// Import React hooks
import React, { useContext, useEffect, useRef, useState } from "react";

// Import Custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Import Context
import AuthContext from "../../../context/auth-context";

// Import Custom Components
import Loading from "../../ui/Loading";

// Import Material UI Components
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";

// Import Context
import FeedbackContext from "../../../context/feedback-context";

import { flexColCentered } from "../../../styles/commonStyles";
import StudyLevels from "../../../types/enums/studyLevels";
import UserRoles from "../../../types/enums/userRoles";

import { useLocation } from "react-router-dom";

const EditUserProfile: React.FC<{}> = () => {
  const authCtx = useContext(AuthContext);

  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [studyLevel, setStudyLevel] = useState<StudyLevels>(
    StudyLevels.BACHELOR
  );
  const [gender, setGender] = useState<string>("Male");
  const [error, setError] = useState<string>();
  const [formChanged, setFormChanged] = useState(false);

  const { loading, sendRequest } = useSendRequestWithToken();

  const feedbackCtx = useContext(FeedbackContext);

  // get the state_userid
  const location = useLocation();
  let state_userid: string = null;
  if (location.state && "userid" in location.state)
    state_userid = location.state.userid;
  const profile_url = state_userid
    ? `/profile?state_userid=${state_userid}`
    : `/profile`;

  // Some refs needed
  const firstnameInputRef = useRef<HTMLInputElement>();
  const lastnameInputRef = useRef<HTMLInputElement>();
  const emailInputRef = useRef<HTMLInputElement>();
  const universityInputRef = useRef<HTMLInputElement>();
  const facultyInputRef = useRef<HTMLInputElement>();

  const updateProfileHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      firstname: firstnameInputRef.current.value,
      lastname: lastnameInputRef.current.value,
      email: emailInputRef.current.value,
      gender: gender,
      studyLevel: studyLevel,
      uni: universityInputRef.current.value,
      faculty: facultyInputRef.current.value,
      roles: roles,
    };

    try {
      await sendRequest("put", profile_url, data);
      feedbackCtx.showFeedback("Updated profile successfully", "success");
      if (!state_userid) authCtx.reloadRoles(roles); // only update roles when not editing another user!
      setFormChanged(false);
      setError(null);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback(
        "Updating profile failed: " + error_msg,
        "error"
      );
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await sendRequest("GET", profile_url, {});
        const userData = response.data.user;
        firstnameInputRef.current.value = userData.firstname;
        lastnameInputRef.current.value = userData.lastname;
        emailInputRef.current.value = userData.email;
        universityInputRef.current.value = userData.uni;
        facultyInputRef.current.value = userData.faculty;
        setRoles(userData.roles);
        setGender(userData.gender);
        setStudyLevel(userData.studyLevel);
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rolesOptions: UserRoles[] = Object.values(UserRoles);
  const studyLevelOptions: StudyLevels[] = Object.values(StudyLevels);

  return (
    <>
      {loading && <Loading />}
      <Box sx={{ ...flexColCentered }}>
        <Grid
          component="form"
          onSubmit={updateProfileHandler}
          container
          justifyContent="center"
          spacing={2}
          sx={{
            width: "80%",
            p: 2,
          }}
        >
          <Grid item xs={12}>
            {error && (
              <Box
                component="h5"
                sx={{
                  m: 0,
                  p: "1rem",
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,0,0,0.8)",
                  backgroundColor: "rgba(255,0,0,0.1)",
                  borderRadius: "5px",
                }}
              >
                {error}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputRef={firstnameInputRef}
              InputLabelProps={{ shrink: true }}
              sx={{ width: "100%" }}
              label="Firstname"
              variant="outlined"
              onChange={() => {
                setFormChanged(true);
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputRef={lastnameInputRef}
              InputLabelProps={{ shrink: true }}
              sx={{ width: "100%" }}
              label="Lastname"
              variant="outlined"
              onChange={() => {
                setFormChanged(true);
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={emailInputRef}
              InputLabelProps={{ shrink: true }}
              sx={{ width: "100%" }}
              label="E-Mail"
              variant="outlined"
              onChange={() => {
                setFormChanged(true);
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={["Male", "Female"]}
              value={gender}
              onChange={(event, newValue) => {
                setGender(newValue);
                setFormChanged(true);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Gender" placeholder="gender" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={rolesOptions}
              value={roles}
              onChange={(event, newValue) => {
                setRoles(newValue);
                setFormChanged(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="roles (For testing only)"
                  placeholder="roles"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              inputRef={facultyInputRef}
              InputLabelProps={{ shrink: true }}
              sx={{ width: "100%" }}
              label="Faculty"
              variant="outlined"
              onChange={() => {
                setFormChanged(true);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              inputRef={universityInputRef}
              InputLabelProps={{ shrink: true }}
              sx={{ width: "100%" }}
              label="University"
              variant="outlined"
              onChange={() => {
                setFormChanged(true);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={studyLevelOptions}
              value={studyLevel}
              onChange={(event, newValue) => {
                setStudyLevel(newValue);
                setFormChanged(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Study Level"
                  placeholder="Study Level"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              sx={{ width: "80%" }}
              type="submit"
              disabled={!formChanged}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditUserProfile;
