// Import React hooks
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Import Context
import AuthContext from "../../../context/auth-context";

// Import MUI components
import {
  Typography,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

// Import custom components
import Loading from "../../ui/Loading";
import { flexColCentered, flexRowCentered } from "../../../styles/commonStyles";

// Import types
import { UserType } from "../../../types/models/users";
import UserRoles from "../../../types/enums/userRoles";

type Props = { userInfo: UserType };

function PayMembershipFee({ userInfo }: Props) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { loading, sendRequest } = useSendRequestWithToken();

  const membershipType =
    authCtx.roles.filter((role: UserRoles) => role === UserRoles.ACTIVEMEMBER)
      .length > 0
      ? "active"
      : "passive";

  const [newMembershipType, setNewMembershipType] = useState(membershipType);

  const expiredDate = new Date(userInfo.membershipExpiryDate);

  const stripeHandler = async () => {
    try {
      const response = await sendRequest(
        "post",
        "/payments/create-checkout-session-membership",
        { membershipType: newMembershipType }
      );
      window.location.replace(response.data.session.url);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelMembershipHandler = async () => {
    try {
      const newRoles = await sendRequest(
        "get",
        "/profile/cancelMembership",
        {}
      );
      console.log(newRoles.data.roles);
      authCtx.reloadRoles(newRoles.data.roles);
      navigate(`/user/${authCtx.userId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid container sx={{ ...flexColCentered, mt: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ my: 3, color: "text.secondary" }}>
            Your membership has expired
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ my: 3, px: "7rem" }}>
            Your membership has expired on the{" "}
            <strong>
              {expiredDate.toLocaleString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </strong>
            . Please pay your membership fee to renew your membership and get
            access again to all the benefits of being a member.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{ ...flexRowCentered, flexDirection: "row", mt: 1 }}
        >
          <Grid
            item
            xs={4}
            sx={{ ...flexRowCentered, flexDirection: "column" }}
          >
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  value={newMembershipType}
                  onChange={(event) => {
                    setNewMembershipType(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="passive"
                    control={<Radio />}
                    label="Passive Member"
                  />
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active Member"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ mt: 3, width: "20rem" }}
                onClick={stripeHandler}
              >
                Renew Membership
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ ...flexRowCentered }}>
            <Button variant="outlined" onClick={cancelMembershipHandler}>
              Cancel Membership
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PayMembershipFee;
