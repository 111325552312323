// Import Hooks
import React, { useState, useEffect } from "react";

// Import Material UI code
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ChildrenProp } from "../types/props/common";

// Import from styles directory
import { darkModePalette, lightModePalette } from "../styles/muiColors";
import { components } from "../styles/muiComponents";

const ThemeContext = React.createContext({
  theme: "light",
  setTheme: (theme: "light" | "dark") => {},
});

export function ThemeContextProvider(props: ChildrenProp) {
  // Since the app is still in the transitional state (using MUI), there are two states, one for a normal context variable and the other for the MUI theming.
  // Goal is to completely switch to MUI themeing. The current solution is a hybrid

  const [theme, setTheme] = useState("light");
  const [themePalette, setThemePalette] = useState(lightModePalette);

  useEffect(() => {
    setThemePalette(theme === "light" ? lightModePalette : darkModePalette);
  }, [theme]);

  // Here some component style can be overwritten globally so that we dont have to do it in every component
  // If this gets too big, we can move it to a seperate file

  // Light MUI theme
  const ThemeMUI = createTheme(themePalette, {
    components: components(themePalette),
  });

  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
      <ThemeProvider theme={ThemeMUI}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
