// Import React hooks
import React, { useState } from "react";

// Import Custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Import MUI components
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Button,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

// Import custom components
import Loading from "../../ui/Loading";
import { flexColCentered } from "../../../styles/commonStyles";

function NonMembers() {
  const [membershipType, setMembershipType] = useState("passive");
  const { loading, sendRequest } = useSendRequestWithToken();

  const stripeHandler = async () => {
    try {
      const response = await sendRequest(
        "post",
        "/payments/create-checkout-session-membership",
        { membershipType: membershipType }
      );
      window.location.replace(response.data.session.url);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      console.log(error_msg);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid container sx={{ ...flexColCentered, mt: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ my: 3, color: "text.secondary" }}>
            You are currently not a member
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ my: 3, px: "7rem" }}>
            As a passive member you support us with a financial contribution
            (annual membership fee) and thus become a part of the association.
            You will receive notifications about all our upcoming events and
            benefit from a special reduced price at any of our events that
            charges an entrance fee. <br />
            <br />
            As an active member, you enjoy the same benefits as passive members.
            Additionally: You can suggest and implement your own idea or
            project! You are entitled to vote at the annual general assembly.
            You can run for a position on the MSAZ board. <br />
            <br />
            The annual membership fee is CHF 30.00 for BSc. and MSc. students
            and CHF 60.00 for PhD and Alumni. We support payment by Card,
            PayPal, Twint and Bank Transfer.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 4 }}>
          <FormControl>
            <RadioGroup
              row
              value={membershipType}
              onChange={(event) => {
                setMembershipType(event.target.value);
              }}
            >
              <FormControlLabel
                value="passive"
                control={<Radio />}
                label="Passive Member"
              />
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active Member"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ mt: 3, width: "20rem" }}
            endIcon={<PersonAddIcon />}
            onClick={stripeHandler}
          >
            Join us!
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default NonMembers;
