// React Components
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// App.js
import App from "./App";

// Import utils
import ScrollToTop from "./utils/ScrollToTop";

// Material UI
import { CssBaseline } from "@mui/material";

// Import some Providers
import { ThemeContextProvider } from "./context/theme-context";
import { AuthContextProvider } from "./context/auth-context";
import { FeedbackContextProvider } from "./context/feedback-context";
import { SocketContextProvider } from "./context/socket-context";
import { NotificationContextProvider } from "./context/notification-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <SocketContextProvider>
        <NotificationContextProvider>
          <ThemeContextProvider>
            <FeedbackContextProvider>
              <ScrollToTop />
              <CssBaseline />
              <App />
            </FeedbackContextProvider>
          </ThemeContextProvider>
        </NotificationContextProvider>
      </SocketContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
