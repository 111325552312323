// Import React components and hooks
import React from "react";
import { Link } from "react-router-dom";

// Import Material UI components
import { Box, Paper, Divider, Button, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TodayIcon from "@mui/icons-material/Today";

// Import Types
import { EventType } from "../../types/models/events";

import vars from "../../confidential.json";

// Create simple MUI wrapped of img tag to be able to use the sx prop

type EventCardProps = { event: EventType };

function EventCard({ event }: EventCardProps) {
  const event_date = new Date(event.eventDate);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          borderRadius: "0.25rem",
          backgroundColor: "background.default",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            py: "0.5rem",
            px: "1rem",
            borderRadius: "0.25rem",
            left: "1rem",
            top: "1rem",
            backgroundColor: "background.secondary",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TodayIcon />
          <Typography sx={{ ml: "1rem" }}>
            {event_date.toLocaleString("en-GB", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            py: "0.5rem",
            px: "1rem",
            borderRadius: "0.25rem",
            right: "1rem",
            top: "1rem",
            backgroundColor: "background.secondary",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <AccessTimeIcon />
          <Typography sx={{ ml: "0.5rem" }}>
            {event_date.toLocaleString("en-GB", {
              hour: "numeric",
              minute: "numeric",
            })}
          </Typography>
        </Box>
        <Box
          component="img"
          src={vars.backend_URL + "/events/" + event.flyerImage.filename}
          sx={{
            borderRadius: "0.25rem 0.25rem 0 0",
            width: "100%",
            m: 0,
          }}
        />

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              pb: 2,
              textAlign: "center",
            }}
          >
            {event.name}
          </Typography>
          <Divider sx={{ width: "100%", mb: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 2,
            }}
          >
            <LocationOnOutlinedIcon sx={{ mr: 1 }} />
            <Typography sx={{ fontWeight: "bold" }}>
              {event.location}
            </Typography>
          </Box>
          <Button
            sx={{ my: 2 }}
            variant="contained"
            component={Link}
            to={`/events/${event._id}`}
            endIcon={<InfoIcon />}
          >
            More Infos
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default EventCard;
