// Import React Components and Hooks
import React from "react";
import { Outlet } from "react-router-dom";

// Custom Hooks
import useGetDimension from "../../../hooks/useGetDimension";

// Material UI Components
import { AppBar, Container, Box } from "@mui/material";

// Import context

// Import tabs from json file
import BigScreen from "./BigScreen";

function Nav() {
  // Custom Hook to get dimension of Nav to calculate the margin needed for the pages
  const { ref, getDimension } = useGetDimension();

  // Create css for the display of the page underneath the nav
  const topMargin = getDimension("height") + 2 * getDimension("top");

  const pageUnderNavCSS = {
    mt: `${topMargin}px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "96%",
          top: "1rem",
          left: "2%",
          borderRadius: "0.25rem",
          backgroundColor: "primary.main",
        }}
        ref={ref}
        position="fixed"
      >
        <Container maxWidth="xl">
          <BigScreen getDimension={getDimension} />
        </Container>
      </AppBar>
      <Box sx={pageUnderNavCSS}>
        <Outlet context={{ topMargin }} />
      </Box>
    </>
  );
}

export default Nav;
