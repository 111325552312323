// import React
import React, { useState, useRef, useContext } from "react";

// import Mui components
import { Box, Button, Grid, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// import custom components
import Loading from "../../ui/Loading";

// import custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Imoport custom styles
import { flexColCentered } from "../../../styles/commonStyles";

//import Context
import SocketContext from "../../../context/socket-context";

function CreateNewChat() {
  const [createNewChat, setCreateNewChat] = useState(false);
  const { loading, sendRequest } = useSendRequestWithToken();
  const userInputRef = useRef<HTMLInputElement>();

  const { chatSocket: socket } = useContext(SocketContext);

  // Crate new Chat
  const handleCreateChat = async () => {
    const data = {
      participant: userInputRef.current.value,
    };
    try {
      await sendRequest("post", "/chat/create", data);
      socket.emit("newChatCreated");
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      console.log(error_msg);
    }
  };

  // opening the container to create a new chat
  const handleOpenCreateNewChat = () => {
    setCreateNewChat((state) => !state);
  };

  return (
    <Box sx={{ ...flexColCentered, width: "100%" }}>
      {loading && <Loading />}
      <IconButton onClick={handleOpenCreateNewChat}>
        <AddIcon />
      </IconButton>
      {createNewChat && (
        <Grid container sx={{ ...flexColCentered, width: "80%" }}>
          <Grid
            item
            xs={12}
            sx={{
              ...flexColCentered,
              width: "100%",
              p: 2,
            }}
          >
            <TextField
              inputRef={userInputRef}
              label="username"
              variant="outlined"
            />
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              onClick={handleCreateChat}
            >
              Create Chat
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default CreateNewChat;
