import React from "react";

function Error(props: { code: number }) {
  let message = "";
  switch (props.code) {
    case 401:
      message = "Unauthorized";
      break;
    case 403:
      message = "Forbidden";
      break;
    case 404:
      message = "Page not found";
      break;
    case 500:
      message = "Internal server error";
      break;
    default:
      message = "Something went wrong";
      break;
  }
  return (
    <>
      <h1>Error has occured</h1>
      <p>{message}</p>
    </>
  );
}

export default Error;
