import React, { useState, useContext } from "react";

// Import Custom hooks
import useSendRequestWithToken from "../../../hooks/useSendRequestWithToken";

// Import MUI components
import {
  Grid,
  Typography,
  Button,
  IconButton,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Import Context
import AuthContext from "../../../context/auth-context";

// Import custom components
import Loading from "../../ui/Loading";
import { flexColCentered } from "../../../styles/commonStyles";

// Import types
import { UserType } from "../../../types/models/users";
import UserRoles from "../../../types/enums/userRoles";

type Props = { userInfo: UserType };

function ManageMembership({ userInfo }: Props) {
  const authCtx = useContext(AuthContext);
  const [manageMembership, setManageMembership] = useState<boolean>(false);
  const { loading, sendRequest } = useSendRequestWithToken();

  const membershipType =
    authCtx.roles.filter((role: UserRoles) => role === UserRoles.ACTIVEMEMBER)
      .length > 0
      ? "active"
      : "passive";

  const [newMembershipType, setNewMembershipType] =
    useState<string>(membershipType);

  const validUntil = new Date(userInfo.membershipExpiryDate);

  const stripeHandler = async () => {
    try {
      const response = await sendRequest(
        "post",
        "/payments/create-checkout-session-membership",
        { membershipType: newMembershipType }
      );
      window.location.replace(response.data.session.url);
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      console.log(error_msg);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Grid container sx={{ ...flexColCentered, mt: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ my: 3, color: "text.secondary" }}>
            Your {membershipType} Membership is paid, Thank you for the support
            :)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ my: 3, px: "7rem" }}>
            Your membership will expire on the{" "}
            <strong>
              {validUntil.toLocaleString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </strong>
          </Typography>
        </Grid>
        {!manageMembership ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ mt: 3, width: "20rem" }}
              onClick={() => {
                setManageMembership(true);
              }}
            >
              Manage Membership
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <IconButton
                onClick={() => {
                  setManageMembership(false);
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  my: 2,
                  fontSize: "0.7rem",
                  color: "rgba(0,0,0,0.5)",
                  fontStyle: "italic",
                }}
              >
                If you want to cancel your membership, you dont have to do
                anything and simply wait until your membership expires. <br />
                Updating the membership has a fee of 0.5 CHF to cover the
                transaction costs.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  value={newMembershipType}
                  onChange={(event) => {
                    setNewMembershipType(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="passive"
                    control={<Radio />}
                    label="Passive Member"
                  />
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active Member"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={membershipType === newMembershipType}
                variant="contained"
                sx={{ mt: 3, width: "20rem" }}
                onClick={stripeHandler}
              >
                Update Membership
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default ManageMembership;
