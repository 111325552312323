// Import React hooks
import React, { useRef, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

// Import Custom hooks
import useSendRequest from "../../hooks/useSendRequest";

// Import Custom Components
import Loading from "../../components/ui/Loading";

// Import Material UI Components
import { Box, Button, Grid, TextField, Autocomplete } from "@mui/material";

// Import Context
import FeedbackContext from "../../context/feedback-context";

// Import utils
import { wait } from "../../utils/helper_functions";
import { RegisterRequest } from "../../types/auth/register";
import UserRoles from "../../types/enums/userRoles";
import StudyLevels from "../../types/enums/studyLevels";

function Register() {
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [studyLevel, setStudyLevel] = useState<StudyLevels>(null);
  const [gender, setGender] = useState<string>(null);
  const [error, setError] = useState<string>(null);

  const { loading, sendRequest } = useSendRequest();
  const feedbackCtx = useContext(FeedbackContext);

  const navigate = useNavigate();

  // Some refs needed
  const firstnameInputRef = useRef<HTMLInputElement>();
  const lastnameInputRef = useRef<HTMLInputElement>();
  const emailInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();
  const confirmPasswordInputRef = useRef<HTMLInputElement>();
  const universityInputRef = useRef<HTMLInputElement>();
  const facultyInputRef = useRef<HTMLInputElement>();

  const registerHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const data: RegisterRequest = {
      firstname: firstnameInputRef.current.value,
      lastname: lastnameInputRef.current.value,
      email: emailInputRef.current.value,
      password: passwordInputRef.current.value,
      gender: gender,
      studyLevel: studyLevel,
      uni: universityInputRef.current.value,
      faculty: facultyInputRef.current.value,
      roles: roles,
    };

    if (
      passwordInputRef.current.value !== confirmPasswordInputRef.current.value
    ) {
      feedbackCtx.showFeedback("Passwords do not match", "error");
      setError("Passwords do not match");
      return;
    }

    try {
      await sendRequest("post", "/auth/register", data, true);

      // TODO: navigate to a page that waits for an email confirmation
      feedbackCtx.showFeedback("Register successfull", "success");
      setError(null);
      await wait(1000);
      navigate(`/auth`, { replace: true });
    } catch (error) {
      const error_msg = error?.response?.data?.error;
      feedbackCtx.showFeedback("Register failed: " + error_msg, "error");
    }
  };

  const rolesOptions: UserRoles[] = Object.values(UserRoles);

  const studyLevelOptions: StudyLevels[] = Object.values(StudyLevels);

  return (
    <>
      {loading && <Loading />}
      <Grid
        component="form"
        onSubmit={registerHandler}
        container
        justifyContent="center"
        spacing={2}
        sx={{
          width: "60%",
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            component="h1"
            sx={{ m: 0, p: "1.5rem", width: "100%", textAlign: "center" }}
          >
            Register
          </Box>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Box
              component="h5"
              sx={{
                m: 0,
                p: "1rem",
                width: "100%",
                textAlign: "center",
                color: "rgba(255,0,0,0.8)",
                backgroundColor: "rgba(255,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              {error}
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={firstnameInputRef}
            sx={{ width: "100%" }}
            label="Firstname"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={lastnameInputRef}
            sx={{ width: "100%" }}
            label="Lastname"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={emailInputRef}
            sx={{ width: "100%" }}
            label="E-Mail"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={passwordInputRef}
            sx={{ width: "100%" }}
            label="Password"
            variant="outlined"
            type="password"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={confirmPasswordInputRef}
            sx={{ width: "100%" }}
            label="Confirm Password"
            variant="outlined"
            type="password"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={["Male", "Female"]}
            value={gender}
            onChange={(event, newValue) => {
              setGender(newValue);
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Gender"
                placeholder="gender"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={rolesOptions}
            value={roles}
            onChange={(event, newValue) => {
              setRoles(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="roles (For testing only)"
                placeholder="roles"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={facultyInputRef}
            sx={{ width: "100%" }}
            label="Faculty"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={universityInputRef}
            sx={{ width: "100%" }}
            label="University"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={studyLevelOptions}
            value={studyLevel}
            onChange={(event, newValue) => {
              setStudyLevel(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Study Level"
                placeholder="Study Level"
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
        >
          <Button variant="contained" sx={{ width: "80%" }} type="submit">
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
          <Button sx={{ width: "80%" }} component={Link} to={`/auth`}>
            I already have an account
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Register;
